import React from 'react'
import styled from 'styled-components'
import { Box } from '../Box'
import { designTokens } from '../Theme/designTokens'
import { CardContainer, CardBody } from '../Card'
import { CSSGrid, Col } from '../Grid'

export const IconBox = styled(Box)`
 display: inline-flex;
 align-items: center;
 justify-content: center;
 border-radius: calc(${designTokens.space[3]} - ${designTokens.space[1]});
 padding: 0;
 width: ${(props) => props.size ? props.size : designTokens.space[6]};
 height: ${(props) => props.size ? props.size : designTokens.space[6]};
`;

export const IconCard = (props) => {
  return(
    <CardContainer>
      <CardBody>
        <CSSGrid>
          <Col col={1/8} media={{ phone: 1/4 }}>
            <IconBox bg={({ theme }) => theme.green} marginBottom={designTokens.space[3]}>
              {props.children}
            </IconBox>
          </Col>
          <Col col={7/8} media={{ phone: 3/4 }}>
            <div style={{ lineHeight: '1.5'}}>
              <strong>{props.cardTitle}</strong>
              <br/>
              {props.cardText}
            </div>
          </Col>
        </CSSGrid>
      </CardBody>
    </CardContainer>
  )
}