import styled from 'styled-components';
import { Link } from 'gatsby';
import { designTokens } from '../Theme/designTokens'

export const LogoIcon = styled.div`
  --bg: ${({ theme }) => theme.green};
  --color: ${({ theme }) => theme.grey0};
  width: calc(${designTokens.space[5]} + ${designTokens.space[2]});
  height: calc(${designTokens.space[5]} + ${designTokens.space[2]});
  display: inline-block;
`;

export const LogoWordmark = styled.div`
  padding-left: ${designTokens.space[3]};
  @media screen and (max-width: ${designTokens.breakpoints[4]}) {
    padding-left: ${designTokens.space[2]};
    small {
      font-size: 11px;
    }
  }
`;

export const LogoWordMarkRepsonsive = styled(LogoWordmark)`
  @media screen and (max-width: ${designTokens.breakpoints[4]}) {
    display: none;
    font-size: 14px;
  }
`;

export const LogoContainer = styled(Link)`
  display: inline-flex;
	align-items: center;
	text-decoration: none;
	color: inherit;
  box-shadow: none;
  border-radius: ${designTokens.space[1]};
  position: relative;
  transition: all 120ms ease-out 0s;

  &:hover, &:focus {
		opacity: .75;
    box-shadow: none;
    background: ${({ theme }) => theme.grey200};
		box-shadow: 0px 0px 0px ${designTokens.space[2]} ${({ theme }) => theme.grey200};
	}
`;

export const LogoLoggedIn = styled.span`
  color: ${({ theme }) => theme.green};
  position: relative;
`;