import React from "react"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../utilities/services/auth"
import { Container, CSSGrid, Col } from './Grid'
import { CardContainer, CardBody } from './Card'
import { Button } from './Button'
import { Intro } from './Intro'
import { designTokens } from './Theme/designTokens'
import ImageIcon from './ImageIcon'
import { Text } from './Text'
import { StyledLink, OutboundLink } from './StyledLink'
import { motion } from 'framer-motion'

import styled from 'styled-components'

const TextField = styled.input`
  display: block;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.grey200};
  background: transparent;
  border-radius: 0.4rem;
  padding: ${designTokens.space[3]} ${designTokens.space[3]};
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-family: inherit;
  color: inherit;
  margin-top: ${designTokens.space[2]};
  margin-bottom: ${designTokens.space[3]};
  transition: all 120ms ease-out 0s;
  &:focus, &:active {
    border: 1px solid ${({ theme }) => theme.green};
    background: ${({ theme }) => theme.grey100};
  }
`;

const FormButton = styled.button`
  background: transparent;
  font-size: ${designTokens.fontSizes[0]};
  border: 0;
  padding: 0;
  user-select: none;
  cursor: pointer;
  color: ${({ theme }) => theme.highlightedText};
  border-radius: 2px;
  transition: all 120ms ease-out 0s;
  &:hover {
    background: ${({ theme }) => theme.greenTransparent};
    box-shadow: 0px 0px 0px ${designTokens.space[1]} ${({ theme }) => theme.greenTransparent};
  }
`;

const TextLabel = styled.label`
  font-size: ${designTokens.fontSizes[0]};
`;

class Login extends React.Component {
  state = {
    username: ``,
    password: ``,
    error: false,
    isHidden: true,
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
    console.log(this.state)
  }

  toggleHidden = event => {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    handleLogin(this.state)
    if (!isLoggedIn()) {
      this.setState({
        error: true
      })
    } else {
      this.setState({
        error: false
      })
    }
  }

  render() {
    if (isLoggedIn()) {
      navigate(`/${this.props.route}`)
    }
    return (
      <Intro
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.1 }}
    >
        <Container>
          <CSSGrid>
            <Col col={1/4} media={{ phone: 1 }}></Col>
            <Col col={1/2} media={{ phone: 1 }}>
              <div style={{
                marginBottom: designTokens.space[3]
              }}>
                <StyledLink to="/">&larr; Back to Work</StyledLink>
              </div>
              <CardContainer>
                <CardBody>
                  <motion.div
                    style={{
                      maxWidth: '240px',
                      margin: `0 auto ${designTokens.space[4]}`
                    }}
                    inital={{ rotate: 0, opacity: 0 }}
                    animate={{
                      rotate: [5, 0, -5, 3, -3, 0],
                      opacity: 1
                    }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    <ImageIcon srcName="password-protect-red"/>
                  </motion.div>
                  <h5 style={{ marginTop: '0' }}>This project is protected</h5>
                  <form
                    method="post"
                    onSubmit={event => {
                      this.handleSubmit(event)
                      navigate(this.props.route)
                    }}
                  >
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}>
                      <TextLabel>
                        Project Password
                      </TextLabel>
                      <FormButton
                        onClick={this.toggleHidden}
                      >
                        {this.state.isHidden ? 'Show' : 'Hide'} Password
                      </FormButton>
                    </div>
                    <TextField
                        type={this.state.isHidden ? 'password' : 'text'}
                        name="password"
                        placeholder="Password"
                        onChange={this.handleUpdate}
                    />
                    {
                      this.state.error ? 
                        (
                          <Text size={designTokens.fontSizes[0]}>Oops, you might have forgotten the password. For access, <OutboundLink underline="true" href="mailto:parag.ryan@gmail.com?subject=Portfolio Password">contact me</OutboundLink>.</Text>
                        ) 
                        : 
                        null
                    }
                    <div style={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}>
                      <Button
                        marginTop={designTokens.space[3]}
                        color={({ theme }) => theme.grey900}
                        bg={({ theme }) => theme.green}
                        type="submit"
                      >
                        Authenticate
                      </Button>
                    </div>
                  </form>
                </CardBody>
              </CardContainer>
            </Col>
            <Col col={1/4} media={{ phone: 1 }}></Col>
          </CSSGrid>
        </Container>
      </Intro>
    )
  }
}
export default Login