import Layout from "../../../../src/components/layout";
import SEO from "../../../../src/components/seo";
import { Intro } from "../../../../src/components/Intro/Intro";
import Connect from "../../../../src/components/Connect";
import { StyledLink, OutboundLink } from "../../../../src/components/StyledLink";
import Projects from "../../../../src/components/Projects";
import { Section, Container, CSSGrid, Col } from "../../../../src/components/Grid";
import { RandomizerAbout } from "../../../../src/components/RandomizerAbout";
import { Box } from "../../../../src/components/Box";
import { Text } from "../../../../src/components/Text";
import { designTokens } from "../../../../src/components/Theme/designTokens";
import { ButtonLink, ButtonOutbound, ButtonOutboundGhost } from "../../../../src/components/Button";
import { CardContainer, CardBody } from "../../../../src/components/Card";
import resumePDF from "../../../../src/static/RyanParag-resume.pdf";
import { Badge } from "../../../../src/components/Badge";
import { Pill } from "../../../../src/components/Pill";
import { List, ListItem, ListInner } from "../../../../src/components/List";
import MasoniteIcon from "../../../../src/static/masonite-small-icon.svg";
import CB911Icon from "../../../../src/static/cb911-small-icon.svg";
import RyanIcon from "../../../../src/static/ryanparag-small-icon.svg";
import XcendaIcon from "../../../../src/static/xcenda-small-icon.svg";
import AmericaIcon from "../../../../src/static/language-en.svg";
import IndiaIcon from "../../../../src/static/language-in.svg";
import SpainIcon from "../../../../src/static/language-es.svg";
import FranceIcon from "../../../../src/static/language-fr.svg";
import JapanIcon from "../../../../src/static/language-jp.svg";
import React from 'react';
export default {
  Layout,
  SEO,
  Intro,
  Connect,
  StyledLink,
  OutboundLink,
  Projects,
  Section,
  Container,
  CSSGrid,
  Col,
  RandomizerAbout,
  Box,
  Text,
  designTokens,
  ButtonLink,
  ButtonOutbound,
  ButtonOutboundGhost,
  CardContainer,
  CardBody,
  resumePDF,
  Badge,
  Pill,
  List,
  ListItem,
  ListInner,
  MasoniteIcon,
  CB911Icon,
  RyanIcon,
  XcendaIcon,
  AmericaIcon,
  IndiaIcon,
  SpainIcon,
  FranceIcon,
  JapanIcon,
  React
};