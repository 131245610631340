import Layout from "../../../../src/components/layout";
import SEO from "../../../../src/components/seo";
import { Intro } from "../../../../src/components/Intro/Intro";
import { StyledLink } from "../../../../src/components/StyledLink";
import Projects from "../../../../src/components/Projects";
import { Container, CSSGrid, Col } from "../../../../src/components/Grid";
import { designTokens } from "../../../../src/components/Theme/designTokens";
import React from 'react';
export default {
  Layout,
  SEO,
  Intro,
  StyledLink,
  Projects,
  Container,
  CSSGrid,
  Col,
  designTokens,
  React
};