import styled from 'styled-components'
import { designTokens } from '../Theme/designTokens'

export const ToggleContainer = styled.button`
  cursor: pointer;
  display: inline-block;
  width: ${designTokens.space[7]};
  height: ${designTokens.space[5]};
  border: 0;
  background: ${({ theme }) => theme.grey200};
  box-shadow: inset 0px 0px 0.2rem rgba(0, 0, 0, 0.2);
  border-radius: 999px;
  touch-action: manipulation;
  position: relative;
  transition: all 200ms ease-out 0s;
  &:focus {
    box-shadow: 0px 0px 0px ${designTokens.space[1]} ${({ theme }) => theme.grey300};
  }
  &:after {
    content: '';
    width: ${designTokens.space[4]};
    height: ${designTokens.space[4]};
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: ${designTokens.space[3]};
    background: ${({ theme }) => theme.grey0};
    transform: translate(-50%, -50%);
    box-shadow: 0px 2px 0.2rem rgba(0, 0, 0, 0.2);
    transition: all 220ms ease-out 0s;
  }
  &.active {
    background: ${({ theme }) => theme.green};
    &:after {
      left: ${designTokens.space[6]};
    }
  }
`;

export const ToggleIcon = styled.div`
  position: absolute;
  width: calc(${designTokens.space[3]} + ${designTokens.space[1]});
  height: calc(${designTokens.space[3]} + ${designTokens.space[1]});
  top: 50%;
  left: calc(${designTokens.space[5]} + ${designTokens.space[1]});
  transform: translateY(-50%);
  &.active {
    left: ${designTokens.space[2]};
    color: ${({ theme }) => theme.grey0};
  }
`;