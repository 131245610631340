import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'
import { designTokens } from './Theme/designTokens'
import { ThemeToggle } from './ThemeToggle'
import { Link } from 'gatsby'
import { Logo } from './Logo'

const navItems = [
  {
    name: 'Work',
    route: '/work',
    local: true
  },{
    name: 'Notes',
    route: 'https://notes.ryanparag.com',
    local: false
  },{
    name: 'About',
    route: '/about',
    local: true
  }
]

const HeaderContainer = styled.header`
  width: 100%;
  padding-top: ${designTokens.space[4]};
  padding-bottom: ${designTokens.space[2]}};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  right: 0;
  backdrop-filter: blur(40px) saturate(200%);
  background: ${({ theme }) => theme.transparent};
  border-bottom: 1px solid ${({ theme }) => theme.grey200};
  &:before {
    content: '';
    width: 100%;
    position: absolute;
    top: 0;
    height: ${designTokens.space[2]};
    background: linear-gradient(to right, ${({ theme }) => theme.green}, ${({ theme }) => theme.blue}, ${({ theme }) => theme.pink});
  }
`;

const HeaderBody = styled.div`
  max-width: ${designTokens.layoutWidth};
  margin: 0 auto;
  position: relative;
  padding-left: ${designTokens.space[3]};
  padding-right: ${designTokens.space[3]};
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
`;

const Nav = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: inline-flex;
  align-items: center;
`;

const NavItem = styled.li`
  margin-bottom: 0;
  &:not(:last-of-type) {
    margin-right: ${designTokens.space[1]};
  }
  &:not(:first-of-type) {
    margin-left: calc(${designTokens.space[1]} + ${designTokens.space[1]});
  }
`;

const NavLinkCSS = css`
  padding: ${designTokens.space[2]} ${designTokens.space[3]};
  @media screen and (max-width: ${designTokens.breakpoints[6]}) {
    padding: ${designTokens.space[2]} ${designTokens.space[2]};
  }
  border-radius: ${designTokens.space[1]};
  transition: all 120ms ease-out 0s;
  &:hover {
    background: ${({ theme }) => theme.grey100};
  }
  &.active {
    color: ${({ theme }) => theme.highlightedText};
    background: ${({ theme }) => theme.greenTransparent};
  }
`

const NavLink = styled(Link)`
  ${NavLinkCSS}
`;

const NavOutbound = styled.a`
  ${NavLinkCSS}
`

const NavigationItem = ({item}) => {
  return(
    <>
      {
        item.local ? (
          <NavItem key={item.name}>
            <NavLink
              to={item.route}
              activeClassName="active"
            >
              {item.name}
            </NavLink>
          </NavItem>
        )
        :
        (
          <NavItem>
            <NavOutbound key={item.name} href={item.route}>
              {item.name}
            </NavOutbound>
          </NavItem>
        )
      }
    </>
  )
}

const Header = ({ theme, toggleTheme, siteRole, siteTitle }) => {
  return(
    <HeaderContainer>
      <HeaderBody>
        <div>
          <Logo siteTitle={siteTitle} siteRole={siteRole} responsive />
        </div>
        <div>
          <Nav>
            {
              navItems.map(item => (
                <NavigationItem item={item}/>
              ))
            }
            <NavItem>
              <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
            </NavItem>
          </Nav>
        </div>
      </HeaderBody>
    </HeaderContainer>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
