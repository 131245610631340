import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8'

const encryptedPass = 'U2FsdGVkX186pZolXy7cTpn4A3VWESxItiSYAGiXm1E='

export const isBrowser = () => typeof window !== "undefined"
export const getUser = () =>
  isBrowser() && window.localStorage.getItem("ryanparagUser")
    ? JSON.parse(window.localStorage.getItem("ryanparagUser"))
    : {}
const setUser = user =>
  window.localStorage.setItem("ryanparagUser", JSON.stringify(user))
export const handleLogin = ({ username, password }) => {
  if (password === CryptoAES.decrypt(encryptedPass, 'portfolio-key').toString(CryptoENC)) {
    return setUser({
      username: `ryan`,
      name: `Ryan Parag`,
      email: `parag.ryan@gmail.com`,
    })
  }
  return false
}
export const isLoggedIn = () => {
  const user = getUser()
  return !!user.username
}
export const logout = callback => {
  setUser({})
  callback()
}