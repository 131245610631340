import React, {Component} from 'react'
import data from './data'
import { ButtonGhost } from '../Button'
import { Box } from '../Box'
import { designTokens } from '../Theme/designTokens'
import styled from 'styled-components'

const TextField = styled.input`
  display: block;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.grey200};
  background: transparent;
  border-radius: 0.4rem;
  padding: ${designTokens.space[3]} ${designTokens.space[3]};
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-family: inherit;
  color: inherit;
  margin-top: ${designTokens.space[2]};
  margin-bottom: ${designTokens.space[3]};
  transition: all 120ms ease-out 0s;
  &:focus, &:active {
    border: 1px solid ${({ theme }) => theme.green};
    background: ${({ theme }) => theme.grey100};
  }
`;

class Plaid extends Component {

  state = {
    sent: true,
    password: ''
  }

  handleUpdate = event => {
    this.setState({
      password: event.target.value,
    })
  }

  handleSubmit = event => {
    if(this.state.password === 'kimiraikkonen') {
      this.setState({
        sent: false
      })
    }
  }

  async postData() {
    try {
      let result = await fetch('https://contact.plaid.com/jobs', {
        method: 'post',
        mode: 'no-cors',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          name: data.name,
          email: data.email,
          role: data.role,
          resume: data.resume,
          portfolio: data.portfolio,
          portfolio_password: data.portfolio_password,
          phone: data.phone,
          github: data.github,
          dribbble: data.dribbble,
          codepen: data.codepen,
          current_company: data.current_company,
          gender: data.gender,
          race: data.race,
          veteran_status: data.veteran_status
        })
      })

      console.log("Application Sent: " + result)
      this.setState({
        sent: true
      })
    } catch(e) {
      console.log(e)
    }
  }

  render() {
    return(
      <div>
        <p>
          P.S.
          <br/>
          I actually applied to this role via API (https://contact.plaid.com/jobs). Here's the raw JSON I sent in the POST request:
        </p>
        <pre>
          <code>
          {"{"}
          <br/>
          {"  "}name: "{data.name}",
          <br/>
          {"  "}email: "{data.email}",
          <br/>
          {"  "}role: "{data.role}",
          <br/>
          {"  "}portfolio: "{data.portfolio}",
          <br/>
          {"  "}portfolio_password: "{data.portfolio_password}",
          <br/>
          {"  "}resume: "{data.resume}",
          <br/>
          {"  "}cover_letter: "{data.cover_letter}",
          <br/>
          {"  "}phone: "{data.phone}",
          <br/>
          {"  "}github: "{data.github}",
          <br/>
          {"  "}dribbble: "{data.dribbble}",
          <br/>
          {"  "}codepen: "{data.codepen}",
          <br/>
          {"  "}current_company: "{data.current_company}",
          <br/>
          {"  "}gender: "{data.gender}",
          <br/>
          {"  "}race: "{data.race}",
          <br/>
          {"  "}veteran_status: "{data.veteran_status}",
          <br/>
          {"}"}
          </code>
        </pre>
        {
          this.state.sent ? (
            <>
              <Box
                color={({ theme }) => theme.highlightedText}
                bg={({ theme }) => theme.greenTransparent}
                marginBottom={designTokens.space[3]}
              >
                Application Sent ✓
              </Box>
              <small>This section is for me, Ryan</small>
              <TextField
                type="text"
                placeholder="Enter password to send again..."
                onChange={this.handleUpdate}
              />
              <ButtonGhost
                onClick={ () => this.handleSubmit() }
                color={({ theme }) => theme.grey900}
              >
                Enable Send
              </ButtonGhost>
            </>
          ) : (
            <ButtonGhost
              onClick={ () => this.postData() }
              color={({ theme }) => theme.highlightedText}
            >
              Send via API
            </ButtonGhost>
          )
        }
      </div>
    )
  }
}

export default Plaid