import React from 'react';
import styled from 'styled-components';
import { designTokens } from '../Theme/designTokens';
import { Grid, GridItem } from 'styled-grid-responsive'

export const Container = styled.div`
  width: 100%;
  max-width: ${designTokens.layoutWidth};
  margin: 0 auto;
  position: relative;
  padding-left: ${designTokens.space[3]};
  padding-right: ${designTokens.space[3]};
`;

export const CSSGrid = styled(Grid)`
  grid-column-gap: ${designTokens.space[4]};
`;

export const Col = styled(GridItem)`

`;

const SectionContainer = styled.section`
  padding-top: ${designTokens.space[7]};
	padding-bottom: ${designTokens.space[8]};
	border-top: 1px solid ${({ theme }) => theme.grey200};
  position: relative;
  &:before {
		content: attr(name);
		width: 100%;
		max-width: ${designTokens.layoutWidth};
		margin: auto;
		display: block;
		padding-left: calc(${designTokens.space[4]} - ${designTokens.space[1]});
		position: relative;
		top: -${designTokens.space[8]};
		font-size: ${designTokens.fontSizes[0]};
		text-transform: uppercase;
		letter-spacing: .1em;
		color: ${({ theme }) => theme.grey400};
	}
`;

export const Section = (props) => {
  return(
    <SectionContainer name={props.section}>
      {props.children}
    </SectionContainer>
  )
}