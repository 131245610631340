export default {
  name: "Ryan Parag",
  email: "parag.ryan@gmail.com",
  role: "Product Designer - Developer Experience",
  portfolio: "https://ryanparag.com",
  portfolio_password: "guyfieri",
  resume: "https://ryanparag.com/about/resume",
  cover_letter: "https://ryanparag.com/plaid",
  phone: "941-735-6602",
  github: "https://github.com/ryan-parag",
  dribbble: "https://dribbble.com/ryanparag",
  codepen: "https://codepen.io/ryanparag",
  current_company: 'Masonite',
  gender: 'male',
  race: 'Asian (Not Hispanic or Latino)',
  veteran_status: 'I am not a veteran'
}