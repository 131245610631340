import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { Section, Container, CSSGrid, Col } from '../Grid'
import { CardOutbound, CardLink, CardContainer, IconImgContainer, CardBody } from '../Card'
import { ButtonGhost } from '../Button'
import { Text } from '../Text'
import ImageTest from '../ImageTest'
import { Link } from 'gatsby'
import { isLoggedIn } from '../../utilities/services/auth'
import { designTokens } from '../Theme/designTokens'
import features from '../../data/features'

const Projects = () => {

  const data = useStaticQuery(graphql`
    query {
      allMdx(sort: { order: ASC, fields: [frontmatter___id] }) {
        edges {
          node {
            frontmatter {
              id
              title
              path
              image
              description
              main
              private
              parent
              sub
            }
          }
        }
      }
    }
  `)

  return(
    <>
      <Section section="Selected Work">
        <Container>
          <CSSGrid>
            {
              data.allMdx.edges.map( item => (
                item.node.frontmatter.main ?
                (
                  <Col col={1 /2} media={{ phone: 1, tablet: 1/2}} key={item.node.frontmatter.title}>
                    <CardLink key={item.node.frontmatter.title} to={`${item.node.frontmatter.path}`}>
                      <CardContainer>
                        <ImageTest filename={item.node.frontmatter.image}/>
                        <CardBody>
                        <h5 style={{marginTop: '0'}}>
                          {item.node.frontmatter.title}
                        </h5>
                        <p>{item.node.frontmatter.description}</p>
                        {
                          item.node.frontmatter.private ? (
                            <ButtonGhost color={isLoggedIn() ? ({ theme }) => theme.highlightedText : ({ theme }) => theme.visited}>
                              { isLoggedIn() ? 'View Project' : 'Private' }
                            </ButtonGhost>
                          )
                          :
                          (
                            <ButtonGhost color={({ theme }) => theme.highlightedText}>View Project</ButtonGhost>
                          )
                        }
                        </CardBody>
                      </CardContainer>
                    </CardLink>
                  </Col>
                )
                :
                null
              ))
            }
          </CSSGrid>
        </Container>
      </Section>
      <Section section="Other Projects">
        <Container>
          <CSSGrid>
          {
              data.allMdx.edges.map( item => (
                item.node.frontmatter.sub ?
                (
                  <Col key={item.node.frontmatter.title}>
                    <CardLink to={item.node.frontmatter.path}>
                      <CardContainer>
                        <CardBody>
                          <IconImgContainer>
                            <ImageTest filename={item.node.frontmatter.image}/>
                          </IconImgContainer>
                          <CSSGrid>
                            <Col col={1/12} media={{ phone: 1/5 }}></Col>
                            <Col col={11/12} media={{ phone: 4/5 }}>
                              <h5 style={{marginTop: '0'}}>
                              {item.node.frontmatter.title}
                              </h5>
                              <p style={{marginBottom: '0'}}>{item.node.frontmatter.description}</p>
                            </Col>
                          </CSSGrid>
                        </CardBody>
                      </CardContainer>
                    </CardLink>
                  </Col>
                )
                :
                null
              ))
            }
          </CSSGrid>
        </Container>
      </Section>
      <Section section="Writing">
        <Container>
          <CSSGrid>
            <Col>
              <h5>Writing:</h5>
            </Col>
            <Col>
              <CardOutbound href="https://notes.ryanparag.com">
                <CardContainer>
                  <CardBody>
                    <IconImgContainer>
                      <ImageTest filename={'notes-icon.png'}/>
                    </IconImgContainer>
                    <CSSGrid>
                      <Col col={1/12} media={{ phone: 1/5 }}></Col>
                      <Col col={11/12} media={{ phone: 4/5 }}>
                        <h5 style={{marginTop: '0'}}>
                        My Notes
                        </h5>
                        <p style={{marginBottom: '0'}}>I wanted to begin designing in the open and sharing what/how I design digital products. These are my collection of notes and ideas.</p>
                      </Col>
                    </CSSGrid>
                  </CardBody>
                </CardContainer>
              </CardOutbound>
            </Col>
            <Col>
              <h5>Featured:</h5>
            </Col>
            {
              features.map(writing => (
                <Col key={writing.title} col={1/2} media={{ phone: 1 }}>
                  <CardOutbound href={writing.link} rel="noreferrer noopener" target="_blank">
                    <CardContainer>
                      <CardBody>
                        <Text
                          marginBottom={designTokens.space[2]}
                          bold
                          color={({ theme }) => theme.highlightedText }>
                            {writing.title}
                        </Text>
                        <Text>{writing.description}</Text>
                      </CardBody>
                    </CardContainer>
                  </CardOutbound>
                </Col>
              ))
            }
          </CSSGrid>
        </Container>
      </Section>
    </>
  )
}

export default Projects