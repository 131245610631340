import React from 'react'
import styled from 'styled-components'
import { designTokens } from '../Theme/designTokens'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import ImageWrap from '../ImageWrap'
import ImageIcon from '../ImageIcon'
import ImageTest from '../ImageTest'


export const CardImg = styled(Img)`
  display: block;
  width: 100%;
  height: auto;
  border-top-left-radius: calc(${designTokens.space[3]} - ${designTokens.space[1]});
  border-top-right-radius: calc(${designTokens.space[3]} - ${designTokens.space[1]});
`;

export const HeaderImg = styled(ImageWrap)`

`;

export const HeaderImgContainer = styled.div`
  display: block;
  width: 100%;
  height: auto;
  border-top-left-radius: calc(${designTokens.space[3]} - ${designTokens.space[1]});
  border-top-right-radius: calc(${designTokens.space[3]} - ${designTokens.space[1]});
  overflow:hidden;
`;

export const IconImg = styled(ImageTest)`

`;

export const IconImgContainer = styled.div`
  border-radius: calc(${designTokens.space[4]} - ${designTokens.space[1]});
  box-shadow: 0px 4px 8px -1px rgba(0,0,0, 0.14), 0px 8px 16px -3px rgba(0,0,0, 0.12);
  position: absolute;
  left: -${designTokens.space[3]};
  top: -${designTokens.space[3]};
  transition: all 220ms ease-out 0s;
  display: block;
  width: 80px;
  height: 78px;
  overflow: hidden;
  @media screen and (max-width: ${designTokens.breakpoints[4]}) {
    left: -${designTokens.space[2]};
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: calc(${designTokens.space[4]} - ${designTokens.space[1]});
    box-shadow: inset 0px 0px 2px ${designTokens.space[1]} rgba(255,255,255,.2);
  }
`;

export const CardBody = styled.div`
  padding: ${(props) => props.paddingY ? props.paddingY : designTokens.space[4]} ${(props) => props.paddingX ? props.paddingX : designTokens.space[4]};
	position: relative; 
`;

export const CardIcon = styled.img`
  border-radius: ${designTokens.space[3]};
  box-shadow: 0px 4px 8px -1px rgba(0,0,0, 0.14), 0px 8px 16px -3px rgba(0,0,0, 0.12);
  position: absolute;
  left: -${designTokens.space[3]};
  top: -${designTokens.space[3]};
  transition: all 220ms ease-out 0s;
`;

export const CardContainer = styled.div`
  display: block;
	border: 1px solid ${({ theme }) => theme.grey200};
	border-radius: calc(${designTokens.space[3]} - ${designTokens.space[1]});
	margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : designTokens.space[5]};
	box-shadow: 0px 2px 2px rgba(0,0,0, 0.14);
  transition: all 120ms ease-out 0s;
  img {
    border-top-left-radius: calc(${designTokens.space[3]} - ${designTokens.space[1]});
		border-top-right-radius: calc(${designTokens.space[3]} - ${designTokens.space[1]});
  }
  &:first-child{
		border-top-left-radius: calc(${designTokens.space[3]} - ${designTokens.space[1]});
		border-top-right-radius: calc(${designTokens.space[3]} - ${designTokens.space[1]});
	}
	&:last-child {
		border-bottom-left-radius: calc(${designTokens.space[3]} - ${designTokens.space[1]});
		border-bottom-right-radius: calc(${designTokens.space[3]} - ${designTokens.space[1]});
  }
`;

export const CardLink = styled(Link)`
  &:hover ${CardIcon}, &:focus ${CardIcon}, &:hover ${IconImgContainer}, &:focus ${IconImgContainer} {
    left: -${designTokens.space[4]};
  }
  &:hover ${CardContainer}, &:focus ${CardContainer} {
    border-color: ${({ theme }) => theme.green};
		opacity: .75;
		box-shadow: 0px 2px 2px rgba(0,0,0, 0.16), 0px 4px 8px -1px rgba(0,0,0, 0.14), 0px 10px 20px -3px rgba(0,0,0, 0.1);
  }
`;

export const CardOutbound = styled.a`
  &:hover ${CardIcon}, &:focus ${CardIcon}, &:hover ${IconImgContainer}, &:focus ${IconImgContainer} {
    left: -${designTokens.space[4]};
  }
  &:hover ${CardContainer}, &:focus ${CardContainer} {
    border-color: ${({ theme }) => theme.green};
    opacity: .75;
    box-shadow: 0px 2px 2px rgba(0,0,0, 0.16), 0px 4px 8px -1px rgba(0,0,0, 0.14), 0px 10px 20px -3px rgba(0,0,0, 0.1);
  }
`

export const CardWithIcon = (props) => {
  return(
    <CardContainer>
      <CardBody>
        <CardIcon width="80" src={props.icon} alt={props.title}/>
        {props.children}
      </CardBody>
    </CardContainer>
  )
}

export const MainProject = (props) => {
  return(
    <CardLink to={props.link}>
      <CardContainer>
        <HeaderImgContainer>
          <HeaderImg srcName={props.image ? props.image : 'chargebacks-preview'} alt={props.title}/>
        </HeaderImgContainer>
        <CardBody>
          {props.children}
        </CardBody>
      </CardContainer>
    </CardLink>
  )
}

export const SubProject = (props) => {
  return(
    <CardLink to={props.link}>
      <CardContainer>
        <CardBody>
          <IconImgContainer>
            <IconImg alt={props.title} srcName={props.image}/>
          </IconImgContainer>
          {props.children}
        </CardBody>
      </CardContainer>
    </CardLink>
  )
}

export const CardWithImage = (props) => {
  return(
    <CardContainer>
      <CardImg src={props.image} alt={props.title} fluid={props.fluid}/>
      <CardBody>
        {props.children}
      </CardBody>
    </CardContainer>
  )
}

export const CardWithImageLink = (props) => {
  return(
    <CardLink to={props.link}>
      <CardWithImage image={props.image}>
        {props.children}
      </CardWithImage>
    </CardLink>
  )
}

export const CardWithIconLink = (props) => {
  return(
    <CardLink to={props.link}>
      <CardContainer>
        <CardBody>
          <CardIcon width="80" src={props.icon} />
          {props.children}
        </CardBody>
      </CardContainer>
    </CardLink>
  )
}