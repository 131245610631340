import styled from 'styled-components'
import { designTokens } from '../Theme/designTokens'

export const Text = styled.div`
  font-size: ${(props) => props.size ? props.size : 'inherit'};
  font-weight: ${(props) => props.bold ? designTokens.fontWeights.bold : designTokens.fontWeights.body};
  color: ${(props) => props.color ? props.color : 'inherit'};
  line-height: ${(props) => props.lineHeight ? props.lineHeight : designTokens.lineHeights.smallHeading};
  margin-top: ${(props) => props.marginTop ? props.marginTop : '0'};
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '0'};
  text-align: ${(props) => props.center ? 'center' : 'left'};
  font-style: ${(props) => props.italic ? 'italic' : 'normal'};
`;