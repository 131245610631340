import { Link } from 'gatsby';
import Layout from "../../../../src/components/layout";
import SEO from "../../../../src/components/seo";
import { IntroProject, Intro, IntroImage } from "../../../../src/components/Intro/Intro";
import { Section, Container, CSSGrid, Col } from "../../../../src/components/Grid";
import { CardWithImageLink, CardContainer, CardBody } from "../../../../src/components/Card";
import { ButtonOutbound, ButtonGhost } from "../../../../src/components/Button";
import { designTokens } from "../../../../src/components/Theme/designTokens";
import { OutboundLink } from "../../../../src/components/StyledLink";
import { CallToAction } from "../../../../src/components/CallToAction";
import { IconBox, IconCard } from "../../../../src/components/IconBox";
import { Box, ProjectBox } from "../../../../src/components/Box";
import { Text } from "../../../../src/components/Text";
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdHammer from 'react-ionicons/lib/MdHammer';
import React from 'react';
export default {
  Link,
  Layout,
  SEO,
  IntroProject,
  Intro,
  IntroImage,
  Section,
  Container,
  CSSGrid,
  Col,
  CardWithImageLink,
  CardContainer,
  CardBody,
  ButtonOutbound,
  ButtonGhost,
  designTokens,
  OutboundLink,
  CallToAction,
  IconBox,
  IconCard,
  Box,
  ProjectBox,
  Text,
  MdPerson,
  MdHammer,
  React
};