import styled from 'styled-components'
import { Link } from 'gatsby'
import { designTokens } from '../Theme/designTokens'

export const StyledLink = styled(Link)`
  padding-bottom: ${designTokens.space[1]};
  box-shadow: ${props => props.underline ? `inset 0 -0.07em 0 ${designTokens.colors.states.green}` : 'inset 0 0 0 rgba(black, 0)'};
  transition: box-shadow .2s ease,-webkit-box-shadow .2s ease;
  &:hover, &:focus {
		box-shadow: inset 0 -1.4em ${designTokens.colors.states.green};
  }
`;

export const OutboundLink = styled.a`
  padding-bottom: ${designTokens.space[1]};
  box-shadow: ${props => props.underline ? `inset 0 -0.07em 0 ${designTokens.colors.states.green}` : 'inset 0 0 0 rgba(black, 0)'};
  transition: box-shadow .2s ease,-webkit-box-shadow .2s ease;
  &:hover, &:focus {
    box-shadow: inset 0 -1.4em ${designTokens.colors.states.green};
  }
`;