import React from 'react'
import styled from 'styled-components'
import { designTokens } from '../Theme/designTokens'
import MdMail from 'react-ionicons/lib/MdMail'
import LogoGithub from 'react-ionicons/lib/LogoGithub'
import LogoCodepen from 'react-ionicons/lib/LogoCodepen'
import LogoDribbble from 'react-ionicons/lib/LogoDribbble'
import LogoTwitter from 'react-ionicons/lib/LogoTwitter'
import { motion } from 'framer-motion'

const iconSize = "32px"

const connectItems = [
  {
    name: 'email',
    link: 'mailto:parag.ryan@gmail.com?subject=Hey Ryan!',
    icon: <MdMail fontSize={iconSize} color="currentColor"/>
  },{
    name: 'codepen',
    link: 'https://codepen.io/ryanparag',
    icon: <LogoCodepen fontSize={iconSize} color="currentColor"/>
  },{
    name: 'github',
    link: 'https://github.com/ryan-parag',
    icon: <LogoGithub fontSize={iconSize} color="currentColor"/>
  },{
    name: 'dribbble',
    link: 'https://dribbble.com/ryanparag',
    icon: <LogoDribbble fontSize={iconSize} color="currentColor"/>
  }
]

const ConnectList = styled(motion.ul)`
  display: inline-flex;
  flex-wrap:wrap;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const ConnectItem = styled.li`
  line-height: 1;
  margin-bottom: 0;
  font-size: 2.8rem;
  &:not(:last-of-type) {
    margin-right: ${designTokens.space[3]};
  }
`;

const ConnectLink = styled.a`
  padding-bottom: ${designTokens.space[1]};
  box-shadow: none;
  transition: box-shadow .2s ease,-webkit-box-shadow .2s ease;
  &:hover, &:focus {
		box-shadow: inset 0 -1.4em ${designTokens.colors.states.green};
	}
`

const Connect = () => {
  return(
    <ConnectList
      initial={{ opacity: 0, y: `-${designTokens.space[3]}` }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.3 }}
    >
        {
          connectItems.map(item => (
            <ConnectItem key={item.name}>
              <ConnectLink target="_blank" href={item.link}>
                {item.icon}
              </ConnectLink>
            </ConnectItem>
          ))
        }
    </ConnectList>
  )
}

export default Connect