import React, { useState } from 'react'
import { designTokens } from '../Theme/designTokens'
import ImageTest from '../ImageTest'
import styled from 'styled-components'
import { motion } from "framer-motion"

const MemojiContainer = styled(motion.div)`
  width: calc(${designTokens.space[9]} + ${designTokens.space[6]});
  height: calc(${designTokens.space[9]} + ${designTokens.space[6]});
  position: relative;
  display: inline-block;
  user-select: none;
  cursor: pointer;
  border-radius: 50%;
  img {
    user-select: none;
    user-drag: none;
  }
  &:before, &:after {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.blueTransparent};
    width: ${designTokens.space[5]};
    height: ${designTokens.space[5]};
    border-radius: 50%;
    top: -${designTokens.space[4]};
    right: -${designTokens.space[1]};
    transition: all 200ms ease-out 0s;
  }
  &:after {
    background: ${({ theme }) => theme.pinkTransparent};
    width: ${designTokens.space[3]};
    height: ${designTokens.space[3]};
    border-radius: 50%;
    top: -${designTokens.space[0]};
    right: -${designTokens.space[4]};
  }
  &:hover {
    background: ${({ theme }) => theme.greenTransparent};
    box-shadow: 0px 0px 0px ${designTokens.space[2]} ${({ theme }) => theme.greenTransparent};
    &:after {
      background: ${({ theme }) => theme.pink};
      top: -${designTokens.space[2]};
      right: -${designTokens.space[3]};
    }
    &:before {
      background: ${({ theme }) => theme.blue};
      top: -${designTokens.space[5]};
      right: ${designTokens.space[2]};
    }
  }
  @media screen and (max-width: ${designTokens.breakpoints[4]}) {
    width: ${designTokens.space[9]};
    height: ${designTokens.space[9]};
  }
`

const memojis = [
  'thanks.png',
  'memoji-nervous.png',
  'memoji-peace.png',
  'memoji-shocked.png',
  'memoji-sleep.png',
  'memoji-stars.png',
  'memoji-surprise.png',
  'memoji-thinking.png',
  'memoji-eyeroll.png'
]

const Memoji = () => {

  const memojiRandom = () => {
    let random = memojis[Math.floor(Math.random() * memojis.length)]
    return random
  }

  const [isRandom, setIsRandom] = useState(memojiRandom())

  return(
    <MemojiContainer
      initial={{ y: designTokens.space[8], opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.4 }}
      role="button"
      title="Click to change memoji"
      onClick={() => setIsRandom(memojiRandom())}
    >
      <ImageTest filename={isRandom}/>
    </MemojiContainer>
  )
}

export default Memoji