import React, { useState } from 'react';
import styled from 'styled-components';
import { designTokens } from '../Theme/designTokens'
import MdRefresh from 'react-ionicons/lib/MdRefresh'

const roles = [
	{ text: "gummy-eating champ 🍬" },
	{ text: "your friendly neighborhood Spider-Man" },
	{ text: "90's hip hop aficionado 🎤" },
	{ text: "former zookeeper 🦒" },
	{ text: "sparkling water enthusiast" },
	{ text: "collector of browser tabs" },
	{ text: "knower of random facts 🤔" },
	{ text: "still hasn't completed The Legend of Zelda: Breath of the Wild" },
	{ text: "currently in Tampa, FL [insert Florida joke here]" },
	{ text: "a morning person 🌅" },
	{ text: "likes to draw letters" },
	{ text: "a Floridian with too many denim shirts" },
	{ text: "fumbling through learning new things" },
	{ text: "inbox-zero person 📥" },
	{ text: "Slack group hoarder"},
	{ text: "constant tinkerer"},
	{ text: "meditates to cooking videos on youtube 👩‍🍳"},
	{ text: "regularly bites off more than he can chew - literally and figuratively"},
	{ text: "like this portfolio - a constant work in progress"},
	{ text: "has strong opinions on movies 🎬"},
	{ text: "makes really good french toast 🍞"},
	{ text: "is constantly curious"},
	{ text: "prefers speaking in giphys"},
	{ text: "learning how to make a great cup of coffee ☕️"},
	{ text: "has never seen the movie Shrek"},
	{ text: "fan of sweet treats 🍭"},
	{ text: "thinks lime Skittles are better than green apple Skittles (🙏 bring them back)"},
	{ text: "is an avid consumer of: news, plane tickets, sneakers, and HBO"},
	{ text: "former grade-school pianist 🎹 (classical and modern)"},
	{ text: "aspiring polyglot 🙋‍♂️"},
	{ text: "not a fan of drinking milk 🥛"},
	{ text: "lurker of other design portfolios 🤫"},
	{ text: "curious about how digital products can cut through noise 🧐"},
	{ text: "sometimes likes to build with code 👨‍💻"},
	{ text: "regularly falls when riding his fixed-gear bicycle 🚴‍♂️"},
	{ text: "learning how to race cars 🏎"},
	{ text: "can fall asleep anywhere 😴"},
	{ text: "introvert, but aspiring ambivert"},
	{ text: "fan of giant robots 🤖"},
];

const RandomTextIcon = styled.span`
	opacity: .5;
	transition: all 120ms ease-out 0s;
	svg {
		fill: currentColor;
		color: inherit;
		transform: translateY(2px);
		transition: all 120ms ease-out 0s;
	}
	@media screen and (max-width: ${designTokens.breakpoints[4]}) {
		svg {
			transform: translateY(4px);
		}
	}
`

const RandomText = styled.span`
	color: ${({ theme }) => theme.highlightedText};
	background: transparent;
	cursor: pointer;
	border-radius: ${designTokens.space[1]};
	transition: all 120ms ease-out 0s;
	&:hover {
		background: ${({ theme }) => theme.greenTransparent};
		box-shadow: 0px 0px 0px calc(${designTokens.space[1]} * 1.5) ${({ theme }) => theme.greenTransparent};
	}
	&:hover ${RandomTextIcon} {
		opacity: 1;
	}
	&:hover ${RandomTextIcon} svg {
		transform: translateY(2px) scale(1.1);
		@media screen and (max-width: ${designTokens.breakpoints[4]}) {
			svg {
				transform: translateY(4px) scale(1.1);
			}
		}
	}
`;

export const RandomizerAbout = () => {

	const roleRandom = () => {
    let random = roles[Math.floor(Math.random() * roles.length)]
    return random
  }

  const [isRandom, setIsRandom] = useState(roleRandom())
	
  return(
    <RandomText role="button" onClick={() => setIsRandom(roleRandom())}>
      { isRandom.text }{' '}
			<RandomTextIcon>
				<MdRefresh color="inherit" />
			</RandomTextIcon>
    </RandomText>
  )
}