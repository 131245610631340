export default [
  {
    title: 'Soul Insight',
    description: 'The Marcus King Band',
    link: 'https://open.spotify.com/album/0gCZKgJohALN8WFpD4wwOp?si=ifEzNSEXQKynLrsdoD8IdQ'
  },{
    title: 'Labcabincalifornia',
    description: 'The Pharcyde',
    link: 'https://open.spotify.com/album/05Qg48LlYGKYdeXrNGg00g?si=9OvZw7C9Tp2MUYuD5mj1Pg'
  },{
    title: 'Succession: Season 1 OST',
    description: 'Nicholas Britell',
    link: 'https://open.spotify.com/album/7IONINkxycqA3eIKlVZ7Bj?si=Lta0m_l2QbqkbEpDE9jMOA'
  },{
    title: 'The Shining',
    description: 'J Dilla',
    link: 'https://open.spotify.com/album/6hXXKsVVTfDbRTEuHaInI5?si=PaVjJXswQXKSjZgHYj2B7Q'
  },{
    title: 'Deloused in the Comatorium',
    description: 'The Mars Volta',
    link: 'https://open.spotify.com/album/0CA2EVHhRPR5VPV78KZw89?si=Z7BIS0QMT3azFfLCrgMIJA'
  },{
    title: 'How Strange, Innocence',
    description: 'Explosions in the Sky',
    link: 'https://open.spotify.com/album/1goXTgNS1FnB8tzwLG2tBM?si=3t6Dzh-1RZiimkVvhPNT_g'
  }
]