import React from 'react'
import styled from 'styled-components'
import { designTokens } from '../Theme/designTokens'
import { CSSGrid, Col } from '../Grid'
import { Text } from '../Text'
import { ButtonOutbound } from '../Button'
import ImageWrap from '../ImageWrap'

export const Box = styled.div`
  padding: ${(props) => props.paddingY ? props.paddingY : designTokens.space[4]} ${(props) => props.paddingY ? props.paddingX : designTokens.space[4]};
  background: ${(props) => props.bg ? props.bg : ({ theme }) => theme.grey100};
  color: ${(props) => props.color ? props.color : 'inherit'};
  border-radius: calc(${designTokens.space[3]} - ${designTokens.space[1]});
  margin-top: ${(props) => props.marginTop ? props.marginTop : '0'};
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '0'};
  text-align: ${(props) => props.center ? 'center' : 'left'};
  position: relative;
  width: 100%;
`;

export const BoxGradientGreen = styled(Box)`
  border: 1px solid ${({ theme }) => theme.grey200};
  background: linear-gradient(to top, ${({ theme }) => theme.greenTransparent}, transparent ${designTokens.space[9]});
  box-shadow: 0px 2px 2px rgba(0,0,0, 0.14);
`

export const BoxGradientPink = styled(Box)`
  border: 1px solid ${({ theme }) => theme.grey200};
  background: linear-gradient(to top, ${({ theme }) => theme.pinkTransparent}, transparent ${designTokens.space[9]});
  box-shadow: 0px 2px 2px rgba(0,0,0, 0.14);
`

const ProjectBoxImage = styled.div`
  width:100%;
  max-width: 104px;
  margin: auto;
`;

export const ProjectBox = (props) => {
  return(
    <CSSGrid>
      <Col col={1/6} media={{ phone: 1 }}></Col>
      <Col col={4/6} media={{ phone: 1 }}>
        <Box center>
          <ProjectBoxImage>
            <ImageWrap srcName={props.image}/>
          </ProjectBoxImage>
          <Text center size={designTokens.fontSizes[1]} bold marginTop={designTokens.space[3]} marginBottom={designTokens.space[3]}>{props.title}</Text>
          <Text center size={designTokens.fontSizes[0]} marginBottom={designTokens.space[3]}>{props.description}</Text>
          <ButtonOutbound shadow bg={({ theme }) => theme.green} href={props.link} target="_blank">Visit Site</ButtonOutbound>
        </Box>
      </Col>
      <Col col={1/6} media={{ phone: 1 }}></Col>
    </CSSGrid>
  )
}

export const ProjectBoxDisabled = (props) => {
  return(
    <CSSGrid>
      <Col col={1/6} media={{ phone: 1 }}></Col>
      <Col col={4/6} media={{ phone: 1 }}>
        <Box center>
          <ProjectBoxImage>
            <ImageWrap srcName={props.image}/>
          </ProjectBoxImage>
          <Text center size={designTokens.fontSizes[1]} bold marginTop={designTokens.space[3]} marginBottom={designTokens.space[3]}>{props.title}</Text>
          <Text center size={designTokens.fontSizes[0]} marginBottom={designTokens.space[3]}>{props.description}</Text>
        </Box>
      </Col>
      <Col col={1/6} media={{ phone: 1 }}></Col>
    </CSSGrid>
  )
}