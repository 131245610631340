import styled from 'styled-components'
import { designTokens } from '../Theme/designTokens'

export const Badge = styled.div`
  display: inline-flex;
  border-radius: ${designTokens.space[2]};
  border: .12rem solid ${({ theme }) => theme.grey300};
  padding: ${designTokens.space[2]} ${designTokens.space[3]};
  background: ${({ theme }) => theme.grey0};
  background: linear-gradient(to top, ${({ theme }) => theme.grey100}, ${({ theme }) => theme.grey0});
  position: relative;
  color: ${(props) => props.color ? props.color : 'inherit'};
  box-shadow: 0px 1px 3px ${({ theme }) => theme.grey200};
  align-items: center;
  margin-top: ${(props) => props.marginTop ? props.marginTop : '0'};
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '0'};
`;