import React from 'react'
import styled from 'styled-components'
import { designTokens } from '../Theme/designTokens'
import { CSSGrid, Col } from '../Grid'
import { Logo } from '../Logo'
import { ButtonGhost } from '../Button'
import Connect from '../Connect'
import { List, ListItem, ListTitle } from '../List'
import { StyledLink, OutboundLink } from '../StyledLink'
import projects from '../../data/projects'
import { isLoggedIn, logout } from "../../utilities/services/auth"
import { navigate } from "gatsby"
import { Pill } from '../Pill'

const FooterButton = styled(ButtonGhost)`
  font-size: ${designTokens.fontSizes[0]};
`

const FooterContainer = styled.footer`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.grey200};
  padding: ${designTokens.space[5]} 0;
  background: linear-gradient(to top, ${({ theme }) => theme.grey100}, ${({ theme }) => theme.grey0});
`;

const FooterBody = styled.div`
  width: 100%;
  max-width: ${designTokens.layoutWidth};
  margin: 0 auto;
  position: relative;
  padding-left: ${designTokens.space[4]};
  padding-right: ${designTokens.space[4]};
`;

const FooterText = styled.small`
  color: ${({ theme }) => theme.grey500};
  line-height: 1.5;
`;

const profileItems = [
  {
    name: 'About',
    route: '/about',
  }, {
    name: 'Resume',
    route: '/about/resume'
  }
]

const mainProjects = projects.filter(project => {
  return project.main
})

const subProjects = projects.filter(project => {
  return !project.main
})

let newDate = new Date()
let month = newDate.getMonth();
let year = newDate.getFullYear();

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const Footer = ({ siteTitle, siteRole }) => {

  return(
    <FooterContainer>
      <FooterBody>
        <CSSGrid>
          <Col col={1/5} media={{ phone: 1/2, tablet: 1/4 }}>
            <List
              marginBottom={designTokens.space[3]}
            >
              <ListItem
                marginBottom={designTokens.space[2]}
              >
                <ListTitle>Profile</ListTitle>
              </ListItem>
              {
                profileItems.map(item => (
                  <ListItem
                    key={item.name}
                    marginBottom={designTokens.space[2]}
                  >
                    <StyledLink to={item.route}>
                      {item.name}
                    </StyledLink>
                  </ListItem>
                ))
              }
              <ListItem
                marginBottom={designTokens.space[2]}
              >
                <OutboundLink href="https://notes.ryanparag.com">
                  Notes
                </OutboundLink>
              </ListItem>
              <ListItem
                marginBottom={designTokens.space[2]}
              >
                <StyledLink to={'/about/style-guide'}>
                  Style Guide
                </StyledLink>
              </ListItem>
              {
              isLoggedIn() ?
              (
                  <ListItem
                    marginBottom={designTokens.space[2]}
                  >
                    <StyledLink to={'/about/presentation'}>
                      Presentation
                    </StyledLink>
                  </ListItem>
              )
              :
              null
            }
            </List>
          </Col>
          <Col col={1/5} media={{ phone: 1/2, tablet: 1/4 }}>
            <List
              marginBottom={designTokens.space[3]}
            >
              <ListItem
                marginBottom={designTokens.space[2]}
              >
                <ListTitle>Selected Work</ListTitle>
              </ListItem>
              {
                mainProjects.map(item => (
                  <ListItem
                    key={item.title}
                    marginBottom={designTokens.space[2]}
                  >
                    <StyledLink to={'/work/' + item.link}>
                      {item.title}
                    </StyledLink>
                  </ListItem>
                ))
              }
            </List>
          </Col>
          <Col col={1/5} media={{ phone: 1/2, tablet: 1/4 }}>
          <List
              marginBottom={designTokens.space[3]}
            >
              <ListItem
                marginBottom={designTokens.space[2]}
              >
                <ListTitle>Other Projects</ListTitle>
              </ListItem>
              <ListItem
                marginBottom={designTokens.space[2]}
              >
                <OutboundLink target="_blank" href="https://tampabay.design">
                  TampaBay.design
                </OutboundLink>
              </ListItem>
              {
                subProjects.map(item => (
                  <ListItem
                    key={item.title}
                    marginBottom={designTokens.space[2]}
                  >
                    <StyledLink to={'/work/' + item.link}>
                      {item.title}
                    </StyledLink>
                  </ListItem>
                ))
              }
            </List>
          </Col>
          <Col col={2/5} media={{ phone: 1/2, tablet: 1/4 }}>
            <Logo siteTitle={siteTitle} siteRole={siteRole}/>
            <div style={{marginTop: designTokens.space[4], marginBottom: designTokens.space[4]}}>
            <Connect/>
            </div>
            {
              isLoggedIn() ?
              (
                <>
                  <FooterButton color={({ theme }) => theme.highlightedText} onClick={event => {
                    event.preventDefault()
                    logout(() => navigate(`/work`))
                  }}>
                    Clear Password
                  </FooterButton>
                </>
              )
              :
              null
            }
          </Col>
          <Col>
              <FooterText>
                Designed using <OutboundLink rel="noopener noreferrer" href="https://www.figma.com/" target="_blank">Figma</OutboundLink>, Hand-coded using <OutboundLink rel="noopener noreferrer" href="https://www.gatsbyjs.org/" target="_blank">Gatsby/React</OutboundLink>, Hosted on <OutboundLink rel="noopener noreferrer" href="https://vercel.com/" target="_blank">Zeit/Vercel</OutboundLink>{' | '}
                <strong>Thanks for visiting!</strong> 🎉💯
                <br/>
                <Pill
                  marginTop={designTokens.space[2]}
                >
                  Last modified {monthNames[month]} {year}
                </Pill>
              </FooterText>
          </Col>
        </CSSGrid>
      </FooterBody>
    </FooterContainer>
  )
}

export default Footer