import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme } from './Theme'
import { GlobalStyles } from './GlobalStyles'
import Header from './header'
import Footer from './Footer'
import Login from './login'
import { isLoggedIn } from "../utilities/services/auth"
import { SpotifyApiContext } from 'react-spotify-api';
import styled from 'styled-components'

const MainContainer = styled.main`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    opacity: 0.75;
    top: 0;
    left: 0;
    right: 0;
    height: 50vh;
    z-index: 0;
    background: linear-gradient(to bottom, ${({ theme }) => theme.grey100}, ${({ theme }) => theme.grey0});
  }
`

const Layout = ({ children }) => {

  const [theme, setTheme] = useState(() => {
    if (typeof window !== 'undefined') {
      const localTheme = localStorage.getItem('theme');
      return localTheme === null || localTheme === 'light' ? 'light' : 'dark'
    }
    return 'light'
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('theme', theme);
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const privateRoutes = [
    `/work/masonite/advisar`,
    `/work/masonite/advisar/`,
    `/work/masonite/masonite-connected`,
    `/work/masonite/masonite-connected/`,
    `/work/masonite/door-configurator`,
    `/work/masonite/door-configurator/`,
    `/work/chargebacks911/merchant-app`,
    `/work/chargebacks911/merchant-app/`,
    `/work/chargebacks911/internal-app`,
    `/work/chargebacks911/internal-app/`,
    `/work/chargebacks911/marketing`,
    `/work/chargebacks911/marketing/`,
    `/work/chargebacks911/email-signature`,
    `/work/chargebacks911/email-signature/`,
    `/work/disputelab`,
    `/work/disputelab/`,
    `/about/portfolio-deck`,
    `/about/portfolio-deck/`,
  ]

  const url = typeof window !== 'undefined' ? window.location.pathname : '';
  const urlTrimmed = url

  const projectAuth = () => {
    if(isLoggedIn()) {
      return true
    } else {
      if(privateRoutes.indexOf(urlTrimmed) == -1) {
        return true
      } else {
        return false
      }
    }
  };

  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, []);


  // const [render, setRender] = useState(false)

  // useEffect(() => {
  //   // setRender(true)
  //   const timeout = setTimeout(() => setRender(true), 20)
  //   return () => clearTimeout(timeout)
  // }, [])

  // if (!render) return null

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              role
            }
          }
        }
      `}
      render={data => (
        <React.Fragment key={isClient}>
          <SpotifyApiContext.Provider value={'AQA-paBPKOMditstDc9ULxBjecgE9lplklgLU0NM3AtJQwR4nmzxeS888XqFKMy1ypKpDnhOO-MUFE6DQQTRmHhzt6wuZ142Fl8xsRPeUye6haKDnbzB9V-Q1crnbiW0DV0'}>
            <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
              <>
                <GlobalStyles />
                <Header toggleTheme={toggleTheme} theme={theme} siteRole={data.site.siteMetadata.role} siteTitle={data.site.siteMetadata.title} />
                <MainContainer>
                  {
                    projectAuth() ?
                    (
                      children
                    )
                    :
                    (
                      <Login route={urlTrimmed} />
                    )
                  }
                </MainContainer>
                <Footer siteRole={data.site.siteMetadata.role} siteTitle={data.site.siteMetadata.title}/>
              </>
            </ThemeProvider>
          </SpotifyApiContext.Provider>
        </React.Fragment>
      )}
    /> 
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
