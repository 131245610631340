import SEO from "../../../../src/components/seo";
import { Intro } from "../../../../src/components/Intro/Intro";
import { StyledLink } from "../../../../src/components/StyledLink";
import Projects from "../../../../src/components/Projects";
import { Container, CSSGrid, Col } from "../../../../src/components/Grid";
import { CardOutbound, CardContainer, CardBody } from "../../../../src/components/Card";
import { designTokens } from "../../../../src/components/Theme/designTokens";
import { Box } from "../../../../src/components/Box";
import { Text } from "../../../../src/components/Text";
import ImageIcon from "../../../../src/components/ImageIcon";
import { ButtonOutbound } from "../../../../src/components/Button";
import portfolioPDF from "../../../../src/static/Ryan Parag-Product Designer_Portfolio.pdf";
import { motion } from 'framer-motion';
import React from 'react';
export default {
  SEO,
  Intro,
  StyledLink,
  Projects,
  Container,
  CSSGrid,
  Col,
  CardOutbound,
  CardContainer,
  CardBody,
  designTokens,
  Box,
  Text,
  ImageIcon,
  ButtonOutbound,
  portfolioPDF,
  motion,
  React
};