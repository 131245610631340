import styled from 'styled-components'
import { designTokens } from '../Theme/designTokens'

export const Pill = styled.div`
  display: inline-flex;
  border-radius: ${designTokens.space[4]};
  border: .12rem solid ${({ theme }) => theme.grey300};
  padding: ${designTokens.space[1]} ${designTokens.space[2]};
  background: transparent;
  font-size: 11px;
  position: relative;
  color: ${(props) => props.color ? props.color : 'inherit'};
  align-items: center;
  margin-top: ${(props) => props.marginTop ? props.marginTop : '0'};
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '0'};
  margin-left: ${(props) => props.marginLeft ? props.marginLeft : '0'};
  margin-right: ${(props) => props.marginRight ? props.marginRight : '0'};
`;