import { Link } from 'gatsby';
import Layout from "../../../../src/components/layout";
import Image from "../../../../src/components/image";
import SEO from "../../../../src/components/seo";
import { Intro } from "../../../../src/components/Intro/Intro";
import Connect from "../../../../src/components/Connect";
import { StyledLink } from "../../../../src/components/StyledLink";
import Projects from "../../../../src/components/Projects";
import { Container, CSSGrid, Col } from "../../../../src/components/Grid";
import Memoji from "../../../../src/components/Memoji";
import { designTokens } from "../../../../src/components/Theme/designTokens";
import { isLoggedIn } from "../../../../src/utilities/services/auth";
import { Pill } from "../../../../src/components/Pill";
import { Badge } from "../../../../src/components/Badge";
import MailIcon from "../../../../src/static/mail.svg";
import { motion } from "framer-motion";
import React from 'react';
export default {
  Link,
  Layout,
  Image,
  SEO,
  Intro,
  Connect,
  StyledLink,
  Projects,
  Container,
  CSSGrid,
  Col,
  Memoji,
  designTokens,
  isLoggedIn,
  Pill,
  Badge,
  MailIcon,
  motion,
  React
};