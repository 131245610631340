export default [
  {
    title: 'Masonite',
    link: 'masonite',
    description: 'Connecting doors to the cloud and simplifying the home-remodeling experience',
    photo: 'masonite-preview',
    icon: 'masonite-icon',
    main: true,
    subProjects: [
      {
        name: 'Masonite Connected',
        link: 'masonite-connected',
        description: 'An "Internet of Things" experience that connects a door to a user\'s smart home',
        photo: 'mc-preview',
        icon: 'An "Internet of Things" experience that connects a door to a user\'s smart home',
      }, {
        name: 'Advisar',
        link: 'advisar',
        description: 'An eCommerce management platform for contractors and internal team members',
        photo: 'advisar-preview',
        icon: '',
      }, {
        name: 'Door Configurator',
        link: 'door-configurator',
        description: 'Improving the experience for creating compatabile doors through a responsive, accessible interface',
        photo: 'door-configurator-preview',
        icon: '',
      }
    ]
  }, {
    title: 'Chargebacks911',
    link: 'chargebacks911',
    description: 'Helping online merchants optimize profitability by intelligently managing payment disputes',
    photo: 'chargebacks911-preview',
    icon: 'cb911-icon',
    main: true,
    subProjects: [
      {
        name: 'Wizard & Merchant App',
        link: 'merchant-app',
        description: 'An onboarding experience and management application for clients',
        photo: 'cb911-wizard-preview',
        icon: '',
      }, {
        name: 'Internal Management Tool',
        link: 'internal-management-tool',
        description: 'A client management tool connected to large external datasets',
        photo: 'cb911-ers-preview',
        icon: '',
      }, {
        name: 'Email Signature Generator',
        link: 'email-signature-generator',
        description: 'Giving team members the ability to create their own email signatures',
        photo: 'cb911-email-preview',
        icon: '',
      }, {
        name: 'Marketing Design & Blog',
        link: 'marketing-design',
        description: 'Desinging a better experience through digital and print communications',
        photo: 'cb911-marketing-preview',
        icon: '',
      }
    ]
  }, {
    title: 'DisputeLab',
    link: 'disputelab',
    description: 'Enabling financial enterprises to filter, optimize, and submit thousands of disputes',
    photo: 'disputelab-preview.png',
    icon: 'disputelab-icon',
    main: true,
    subProjects: []
  }, {
    title: 'SoleVenture',
    link: 'soleventure',
    description: 'Giving freelancers the security of steady income and traditional benefits',
    photo: 'soleventure-preview.png',
    icon: 'soleventure-icon',
    main: true,
    subProjects: []
  }, {
    title: 'Hatch',
    link: 'hatch',
    description: 'A simple app to help randomize pairs for teams that pair program',
    photo: '',
    icon: 'hatch.png',
    main: false,
    subProjects: []
  }, {
    title: 'Slack Themes',
    link: 'slack-themes',
    description: 'Beautiful, curated themes to use in your Slack workspace',
    photo: '',
    icon: 'slack-themes-icon.png',
    main: false,
    subProjects: []
  }, {
    title: 'Race Times',
    link: 'race-times',
    description: 'Tracking the local race times of the Formula 1 race calendar',
    photo: '',
    icon: 'race-times-icon.png',
    main: false,
    subProjects: []
  }, {
    title: 'Design Challenges',
    link: 'design-challenges',
    description: 'Weekly design challenges to help learn, share, and connect',
    photo: '',
    icon: 'tbd-icon.png',
    main: false,
    subProjects: []
  }, {
    title: 'Zakucons',
    link: 'zakucons',
    description: 'Icons inspired by the world of Mobile Suit Gundam',
    photo: '',
    icon: 'zakucons-icon.png',
    main: false,
    subProjects: []
  }, {
    title: 'Playa',
    link: 'playa',
    description: 'Lightweight wireframing with SCSS and BEM',
    photo: '',
    icon: 'playa-icon.png',
    main: false,
    subProjects: []
  }
]