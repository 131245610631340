import styled, { css } from 'styled-components'
import { designTokens } from '../Theme/designTokens'

const ListInnerBase = css`
  display: block;
  width: 100%;
  padding: ${(props) => props.dense ? designTokens.space[2] : designTokens.space[3]} ${designTokens.space[3]}; 
`

export const List = styled.ul`
  margin: ${(props) => props.marginTop ? props.marginTop : '0'} ${(props) => props.marginX ? props.marginX : '0'} ${(props) => props.marginBottom ? props.marginBottom : '0'};
  padding: 0;
  list-style-type: none;
`;

export const ListItem = styled.li`
  padding: 0;
  margin: ${(props) => props.marginTop ? props.marginTop : '0'} ${(props) => props.marginX ? props.marginX : '0'} ${(props) => props.marginBottom ? props.marginBottom : '0'};
  &:not(:last-of-type) {
    border-bottom: ${(props) => props.border ? '1px solid' : '0'};
    border-color: ${({ theme }) => theme.grey200};
  }
`;

export const ListTitle = styled.strong`
  color: ${({ theme }) => theme.grey300}
`;

export const ListOutbound = styled.a`
 ${ListInnerBase}
 transition: all 120ms ease-out 0s;
 &:hover, &:focus {
   background: ${({ theme }) => theme.greenTransparent};
 }
`

export const ListInner = styled.div`
 ${ListInnerBase}
 padding: ${(props) => props.dense ? designTokens.space[2] : designTokens.space[3]} ${designTokens.space[3]}; 
`