export default [
  {
    title: 'Bloomberg Prognosis',
    description: 'Prognosis explores the leading edge of medical advances, and asks who gets—or should get—access to them.',
    link: 'https://open.spotify.com/show/6loJL0aKjHugIOzBLKkaeM?srnd=prognosis-podcast'
  },{
    title: 'Design Details',
    description: 'A weekly conversation about design process and culture.',
    link: 'https://designdetails.fm/'
  },{
    title: 'Tradeoffs',
    description: 'Long-time health care reporter Dan Gorenstein hosts an examination of health care, policy, and people.',
    link: 'https://open.spotify.com/show/7kOpJgExMmsQRYxmpTOgUY'
  },{
    title: 'Marketplace',
    description: 'Through stories, conversations and newsworthy numbers, we help listeners understand the economic world around them.',
    link: 'https://open.spotify.com/show/6zYlX5UGEPmNCWacYUJQGD'
  },{
    title: 'Google Method Podcast',
    description: 'A podcast exploring the design stories behind Google products and the people who make them.',
    link: 'https://open.spotify.com/show/0cIzGlabCGYsPz333wTmtK?si=XinbEfvmRKSo8wQvYJM1-A'
  },{
    title: 'The Bob Lefsetz Podcast',
    description: 'Listen to Bob Lefsetz\'s new podcast where he\'ll address the issues at the core of the music business, internet, and the world at large.',
    link: 'https://open.spotify.com/show/3QQChyYncANduSOs72eCbV'
  },{
    title: 'The Indie Hackers Podcast',
    description: 'Raw conversations with the founders behind profitable online businesses.',
    link: 'https://open.spotify.com/show/4ex8hmrHCPvPohKJb3wsuC'
  }
]