import { designTokens } from './designTokens'

export const lightTheme = {
  bg: designTokens.colors.base.grey400,
  color: designTokens.colors.states.green,
  grey900: designTokens.colors.base.grey900,
  grey800: designTokens.colors.base.grey800,
  grey700: designTokens.colors.base.grey700,
  grey600: designTokens.colors.base.grey600,
  grey500: designTokens.colors.base.grey500,
  grey400: designTokens.colors.base.grey400,
  grey300: designTokens.colors.base.grey300,
  grey200: designTokens.colors.base.grey200,
  grey100: designTokens.colors.base.grey100,
  grey0: designTokens.colors.base.grey0,
  green: designTokens.colors.states.green,
  blue: designTokens.colors.states.blue,
  pink: designTokens.colors.states.pink,
  greenTransparent: designTokens.colors.states.greenTransparent,
  blueTransparent: designTokens.colors.states.blueTransparent,
  pinkTransparent: designTokens.colors.states.pinkTransparent,
  transparent: 'hsla(0, 0%, 100%, 0.75)',
  visited: designTokens.colors.states.visited,
  highlightedText: designTokens.colors.states.greenDark,
  tertiaryDark: designTokens.colors.states.blueDark,
}

export const darkTheme = {
  bg: designTokens.colors.base.grey900,
  color: designTokens.colors.states.pink,
  grey900: designTokens.colors.base.grey0,
  grey800: designTokens.colors.base.grey100,
  grey700: designTokens.colors.base.grey200,
  grey600: designTokens.colors.base.grey300,
  grey500: designTokens.colors.base.grey400,
  grey400: designTokens.colors.base.grey500,
  grey300: designTokens.colors.base.grey600,
  grey200: designTokens.colors.base.grey700,
  grey100: designTokens.colors.base.grey800,
  grey0: designTokens.colors.base.grey900,
  green: designTokens.colors.states.green,
  blue: designTokens.colors.states.blue,
  pink: designTokens.colors.states.pink,
  greenTransparent: designTokens.colors.states.greenTransparent,
  blueTransparent: designTokens.colors.states.blueTransparent,
  pinkTransparent: designTokens.colors.states.pinkTransparent,
  transparent: 'hsla(220, 24%, 7%, 0.75)',
  visited: designTokens.colors.states.pink,
  highlightedText: designTokens.colors.states.green,
  tertiaryDark: designTokens.colors.states.blue,
}