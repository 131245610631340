module.exports = [{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-63443247-4"},
    },{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/vercel/workpath0/node_modules/gatsby-remark-images","id":"00ff09d7-6c1f-5e0d-a686-3b7e79ef3bc2","name":"gatsby-remark-images","version":"3.2.2","pluginOptions":{"plugins":[],"maxWidth":960},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{"default":"/vercel/workpath0/src/components/layout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":960,"markdownCaptions":true,"linkImagesToOriginal":false}}]},
    },{
      plugin: require('/vercel/workpath0/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960},
    },{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ryanparag.com","short_name":"portfolio","start_url":"/","background_color":"#00d1b2","theme_color":"#00d1b2","display":"minimal-ui","icon":"src/images/favicon.png"},
    }]
