import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { designTokens } from '../Theme/designTokens'
import MdPlay from 'react-ionicons/lib/MdPlay'
import { CardOutbound, CardContainer, CardBody } from '../../components/Card'
import { Text } from '../../components/Text'

const SpotifyContainer = styled(CardBody)`
  display: flex;
  align-items: center;
`

const SpotifyIcon = styled.div`
  width: ${designTokens.space[6]};
  height: ${designTokens.space[6]};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`
const SpotifyBody = styled.div`
  width: 100%;
  padding-left: ${designTokens.space[3]};
  padding-right: ${designTokens.space[1]};
`

const SpotifyAlbum = styled.img`
  width: ${designTokens.space[7]};
  height: ${designTokens.space[7]};
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  @media screen and (max-width: ${designTokens.breakpoints[4]}) {
    display: none;
  }
`

const Spotify = () => (
  <StaticQuery
    query={graphql`
      query {
        allSpotifyRecentTrack {
          edges {
            node {
              id
              spotifyId
              played_at
              track {
                name
                href
                external_urls {
                  spotify
                }
                artists {
                  name
                }
                album {
                  name
                  images {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <CardOutbound href={data.allSpotifyRecentTrack.edges[0].node.track.external_urls.spotify} target="_blank">
          <CardContainer>
            <SpotifyContainer>
              <SpotifyIcon>
                <MdPlay color={designTokens.colors.states.pink} />
              </SpotifyIcon>
              <SpotifyBody>
                <div>
                  <small>
                    <Text color={({ theme }) => theme.grey500}>Last Listen on Spotify:</Text>
                  </small>
                </div>
                <small>
                  <Text bold marginBottom={designTokens.space[1]} marginTop={designTokens.space[1]}>{data.allSpotifyRecentTrack.edges[0].node.track.name}</Text>
                </small>
                <small>
                  <Text>
                    {data.allSpotifyRecentTrack.edges[0].node.track.album.name} • {data.allSpotifyRecentTrack.edges[0].node.track.artists.map(artist => ( artist.name ))}
                  </Text>
                </small>
              </SpotifyBody>
              <SpotifyAlbum src={data.allSpotifyRecentTrack.edges[0].node.track.album.images[0].url}/>
            </SpotifyContainer>
          </CardContainer>
      </CardOutbound>
    )}
  />
)
export default Spotify

{/* <StaticQuery
  query={graphql`
    query {
      placeholderImage: file(relativePath: { eq: "gatsby-astronaut.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `}
  render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
/> */}