import { graphql, Link } from "gatsby";
import Layout from "../../../../src/components/layout";
import SEO from "../../../../src/components/seo";
import { IntroProject, Intro, IntroImage } from "../../../../src/components/Intro/Intro";
import { Section, Container, CSSGrid, Col } from "../../../../src/components/Grid";
import { MainProject, CardStaticImg, CardLink, CardContainer, CardBody } from "../../../../src/components/Card";
import { ButtonGhost } from "../../../../src/components/Button";
import ImageWrap from "../../../../src/components/ImageWrap";
import { designTokens } from "../../../../src/components/Theme/designTokens";
import projects from "../../../../src/data/projects";
import { IconBox, IconCard } from "../../../../src/components/IconBox";
import { Box, ProjectBox } from "../../../../src/components/Box";
import { CallToAction } from "../../../../src/components/CallToAction";
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdFlag from 'react-ionicons/lib/MdFlag';
import React from 'react';
export default {
  graphql,
  Link,
  Layout,
  SEO,
  IntroProject,
  Intro,
  IntroImage,
  Section,
  Container,
  CSSGrid,
  Col,
  MainProject,
  CardStaticImg,
  CardLink,
  CardContainer,
  CardBody,
  ButtonGhost,
  ImageWrap,
  designTokens,
  projects,
  IconBox,
  IconCard,
  Box,
  ProjectBox,
  CallToAction,
  MdPerson,
  MdFlag,
  React
};