import React from 'react'
import { LogoContainer, LogoIcon, LogoWordmark, LogoWordMarkRepsonsive, LogoLoggedIn } from './Logo.styled'
import { isLoggedIn } from "../../utilities/services/auth"


export const Logo = ({ responsive, siteTitle, siteRole }) => {
  return (
    <LogoContainer to={"/"} title={isLoggedIn() ? 'Ryan Parag | You\'re logged in!' : 'Ryan Parag | projects are private'}>
      <LogoIcon>
        <svg viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
        <path d="M128 256C184.089 256 215.269 256 235.635 235.635C256 215.269 256 184.089 256 128C256 71.9111 256 40.7305 235.635 20.3652C215.269 -3.39084e-06 184.089 0 128 0C71.9111 0 40.7305 -3.39084e-06 20.3652 20.3652C-3.39084e-06 40.7305 0 71.9111 0 128C0 184.089 -3.39084e-06 215.269 20.3652 235.635C40.7305 256 71.9111 256 128 256Z" fill="var(--bg)"/>
        <g clipPath="url(#clip1)" filter="url(#filter0_d)">
        <g clipPath="url(#clip2)">
        <path d="M156.764 57.3474C158.478 50.9508 150.487 46.5265 145.976 51.3741L82.4124 119.67C79.1487 123.176 80.7918 128.896 85.419 130.136L109.302 136.535C112.692 137.444 114.704 140.928 113.795 144.317L99.2362 198.653C97.5222 205.049 105.513 209.474 110.025 204.626L173.588 136.331C176.852 132.824 175.208 127.104 170.581 125.864L146.698 119.465C143.308 118.557 141.297 115.073 142.205 111.683L156.764 57.3474Z" fill="var(--color)"/>
        </g>
        </g>
        <g style={{ mixBlendMode: 'overlay' }}>
        <path d="M128 256C184.089 256 215.269 256 235.635 235.635C256 215.269 256 184.089 256 128C256 71.9111 256 40.7305 235.635 20.3652C215.269 -3.39084e-06 184.089 0 128 0C71.9111 0 40.7305 -3.39084e-06 20.3652 20.3652C-3.39084e-06 40.7305 0 71.9111 0 128C0 184.089 -3.39084e-06 215.269 20.3652 235.635C40.7305 256 71.9111 256 128 256Z" fill="url(#paint0_linear)"/>
        </g>
        <path d="M128 256C184.089 256 215.269 256 235.635 235.635C256 215.269 256 184.089 256 128C256 71.9111 256 40.7305 235.635 20.3652C215.269 -3.39084e-06 184.089 0 128 0C71.9111 0 40.7305 -3.39084e-06 20.3652 20.3652C-3.39084e-06 40.7305 0 71.9111 0 128C0 184.089 -3.39084e-06 215.269 20.3652 235.635C40.7305 256 71.9111 256 128 256Z" fill="url(#paint1_linear)"/>
        <path d="M128 256C184.089 256 215.269 256 235.635 235.635C256 215.269 256 184.089 256 128C256 71.9111 256 40.7305 235.635 20.3652C215.269 -3.39084e-06 184.089 0 128 0C71.9111 0 40.7305 -3.39084e-06 20.3652 20.3652C-3.39084e-06 40.7305 0 71.9111 0 128C0 184.089 -3.39084e-06 215.269 20.3652 235.635C40.7305 256 71.9111 256 128 256Z" fill="url(#paint2_linear)"/>
        <g style={{ mixBlendMode: 'soft-light'}} filter="url(#filter1_f)">
        <path d="M234.574 234.574C224.7 244.448 212.164 249.452 194.996 251.97C177.777 254.496 156.087 254.5 128 254.5C99.9128 254.5 78.2227 254.496 61.004 251.97C43.8359 249.452 31.2996 244.448 21.4259 234.574C11.5522 224.7 6.54845 212.164 4.02977 194.996C1.50366 177.777 1.5 156.087 1.5 128C1.5 99.9128 1.50366 78.2227 4.02977 61.004C6.54845 43.8359 11.5522 31.2996 21.4259 21.4259C31.2996 11.5522 43.8359 6.54845 61.004 4.02977C78.2227 1.50366 99.9128 1.5 128 1.5C156.087 1.5 177.777 1.50366 194.996 4.02977C212.164 6.54845 224.7 11.5522 234.574 21.4259C244.448 31.2996 249.452 43.8359 251.97 61.004C254.496 78.2227 254.5 99.9128 254.5 128C254.5 156.087 254.496 177.777 251.97 194.996C249.452 212.164 244.448 224.7 234.574 234.574Z" stroke="url(#paint3_linear)" strokeWidth="3"/>
        </g>
        <g style={{ mixBlendMode: 'soft-light'}} filter="url(#filter2_f)">
        <path d="M234.221 234.221C224.45 243.991 212.03 248.966 194.923 251.476C177.749 253.995 156.101 254 128 254C99.8986 254 78.2508 253.995 61.0766 251.476C43.9698 248.966 31.5502 243.991 21.7795 234.221C12.0087 224.45 7.03417 212.03 4.52447 194.923C2.00488 177.749 2 156.101 2 128C2 99.8986 2.00488 78.2508 4.52447 61.0766C7.03417 43.9698 12.0087 31.5502 21.7795 21.7795C31.5502 12.0087 43.9698 7.03417 61.0766 4.52447C78.2508 2.00488 99.8986 2 128 2C156.101 2 177.749 2.00488 194.923 4.52447C212.03 7.03417 224.45 12.0087 234.221 21.7795C243.991 31.5502 248.966 43.9698 251.476 61.0766C253.995 78.2508 254 99.8986 254 128C254 156.101 253.995 177.749 251.476 194.923C248.966 212.03 243.991 224.45 234.221 234.221Z" stroke="url(#paint4_linear)" strokeWidth="4"/>
        </g>
        </g>
        <defs>
        <filter id="filter0_d" x="-4.44444" y="-2" width="264.889" height="264" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="2"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        <filter id="filter1_f" x="-2" y="-2" width="260" height="260" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur"/>
        </filter>
        <filter id="filter2_f" x="-6" y="-6" width="268" height="268" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur"/>
        </filter>
        <linearGradient id="paint0_linear" x1="-3.00247" y1="5.12441e-07" x2="67.0798" y2="300.116" gradientUnits="userSpaceOnUse">
        <stop stopOpacity="0"/>
        <stop offset="0.605667" stopOpacity="0.0781197"/>
        <stop offset="1" stopOpacity="0.26"/>
        </linearGradient>
        <linearGradient id="paint1_linear" x1="-3.00247" y1="5.12441e-07" x2="67.0798" y2="300.116" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.26"/>
        <stop offset="0.471233" stopColor="white" stopOpacity="0.0781197"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint2_linear" x1="-3.00247" y1="5.12441e-07" x2="67.0798" y2="300.116" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.26"/>
        <stop offset="0.471233" stopColor="white" stopOpacity="0.0781197"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint3_linear" x1="128" y1="256" x2="128" y2="216" gradientUnits="userSpaceOnUse">
        <stop/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint4_linear" x1="128" y1="0" x2="128" y2="128" gradientUnits="userSpaceOnUse">
        <stop stop-color="white"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <clipPath id="clip0">
        <rect width="256" height="256" fill="white"/>
        </clipPath>
        <clipPath id="clip1">
        <rect width="256.889" height="256" fill="white" transform="translate(-0.444443)"/>
        </clipPath>
        <clipPath id="clip2">
        <rect width="256.889" height="256" fill="white" transform="translate(-0.444443)"/>
        </clipPath>
        </defs>
        </svg>
      </LogoIcon>
      {
        responsive ? (
          <LogoWordMarkRepsonsive>
            <strong>{siteTitle} {isLoggedIn() ? (<LogoLoggedIn>+</LogoLoggedIn>) : null}</strong>
            <br />
            <small>{siteRole}</small>
          </LogoWordMarkRepsonsive>
        )
        :
        (
          <LogoWordmark>
            <strong>
              {siteTitle}
              {
                isLoggedIn() ? (
                  <LogoLoggedIn>{' '}+</LogoLoggedIn>
                )
                :
                null
              }
            </strong>
            <br />
            <small>{siteRole}</small>
          </LogoWordmark>
        )
      }
    </LogoContainer>
  )
}
