import React from 'react'
import styled from 'styled-components';
import ImageWrap from '../ImageWrap'
import { StyledLink } from '../StyledLink'
import { Badge } from '../Badge'
import { Container, CSSGrid, Col } from '../Grid'
import { designTokens } from '../Theme/designTokens'
import { motion } from 'framer-motion'

export const Intro = styled(motion.div)`
  padding-top: calc(${designTokens.space[9]} + ${designTokens.space[7]});
  padding-bottom: ${designTokens.space[9]};
  @media screen and (max-width: ${designTokens.breakpoints[4]}) {
    padding-top: ${designTokens.space[9]};
  }
`;

export const IntroImage = styled(ImageWrap)`
  
`;

export const IntroImageOverlay = styled(motion.div)`
  border-radius: calc(${designTokens.space[3]} - ${designTokens.space[1]});
  background-size: cover;
  background-size: cover;
  background-position: top center;
  border: 1px solid ${({ theme }) => theme.grey200};
  position: relative;
  margin-top: ${designTokens.space[3]};
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
`;

export const IntroProject = (props) => {
  return(
    <Container>
      <CSSGrid>
        <Col>
          <Intro
            intial={{ opacity: 0, y: `-${designTokens.space[8]}`}}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <div style={{ lineHeight: '1.6' }}>
              <StyledLink underline="true" to={'/work'}>Work</StyledLink>{' '}{' '}/{' '}{' '}
              {
                props.subProject ?
                (
                <>
                  <StyledLink underline="true" to={'/work/' + props.subProjectLink}>{props.subProject}</StyledLink><span>{' '}{' '}/{' '}{' '}</span>
                </>
                )
                :
                null
              }
              <strong>{props.projectName}</strong>
            </div>
            <h2>{props.projectName}</h2>
            <IntroImageOverlay
              initial={{ opacity: 0, y: `-${designTokens.space[6]}`}}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.25, delay: 0.25 }}
            >
              <IntroImage alt={props.projectName} srcName={props.projectImage}/>
            </IntroImageOverlay>
            <h5>{props.projectDescription}</h5>
            {
              props.projectCaption ? (
                <p>{props.projectCaption}</p>
              )
              :
              null
            }
            <Badge color={({ theme }) => theme.highlightedText}><strong>{props.projectYear}</strong></Badge>
          </Intro>
        </Col>
      </CSSGrid>
    </Container>
  )
}