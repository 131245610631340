// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/vercel/workpath0/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("/vercel/workpath0/src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-about-index-mdx": () => import("/vercel/workpath0/src/pages/about/index.mdx" /* webpackChunkName: "component---src-pages-about-index-mdx" */),
  "component---src-pages-about-presentation-index-mdx": () => import("/vercel/workpath0/src/pages/about/presentation/index.mdx" /* webpackChunkName: "component---src-pages-about-presentation-index-mdx" */),
  "component---src-pages-about-resume-index-mdx": () => import("/vercel/workpath0/src/pages/about/resume/index.mdx" /* webpackChunkName: "component---src-pages-about-resume-index-mdx" */),
  "component---src-pages-about-style-guide-index-mdx": () => import("/vercel/workpath0/src/pages/about/style-guide/index.mdx" /* webpackChunkName: "component---src-pages-about-style-guide-index-mdx" */),
  "component---src-pages-index-mdx": () => import("/vercel/workpath0/src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-plaid-mdx": () => import("/vercel/workpath0/src/pages/plaid.mdx" /* webpackChunkName: "component---src-pages-plaid-mdx" */),
  "component---src-pages-stripe-mdx": () => import("/vercel/workpath0/src/pages/stripe.mdx" /* webpackChunkName: "component---src-pages-stripe-mdx" */),
  "component---src-pages-work-chargebacks-911-email-signature-index-mdx": () => import("/vercel/workpath0/src/pages/work/chargebacks911/email-signature/index.mdx" /* webpackChunkName: "component---src-pages-work-chargebacks-911-email-signature-index-mdx" */),
  "component---src-pages-work-chargebacks-911-index-mdx": () => import("/vercel/workpath0/src/pages/work/chargebacks911/index.mdx" /* webpackChunkName: "component---src-pages-work-chargebacks-911-index-mdx" */),
  "component---src-pages-work-chargebacks-911-internal-app-index-mdx": () => import("/vercel/workpath0/src/pages/work/chargebacks911/internal-app/index.mdx" /* webpackChunkName: "component---src-pages-work-chargebacks-911-internal-app-index-mdx" */),
  "component---src-pages-work-chargebacks-911-marketing-index-mdx": () => import("/vercel/workpath0/src/pages/work/chargebacks911/marketing/index.mdx" /* webpackChunkName: "component---src-pages-work-chargebacks-911-marketing-index-mdx" */),
  "component---src-pages-work-chargebacks-911-merchant-app-index-mdx": () => import("/vercel/workpath0/src/pages/work/chargebacks911/merchant-app/index.mdx" /* webpackChunkName: "component---src-pages-work-chargebacks-911-merchant-app-index-mdx" */),
  "component---src-pages-work-design-challenges-index-mdx": () => import("/vercel/workpath0/src/pages/work/design-challenges/index.mdx" /* webpackChunkName: "component---src-pages-work-design-challenges-index-mdx" */),
  "component---src-pages-work-disputelab-index-mdx": () => import("/vercel/workpath0/src/pages/work/disputelab/index.mdx" /* webpackChunkName: "component---src-pages-work-disputelab-index-mdx" */),
  "component---src-pages-work-hatch-index-mdx": () => import("/vercel/workpath0/src/pages/work/hatch/index.mdx" /* webpackChunkName: "component---src-pages-work-hatch-index-mdx" */),
  "component---src-pages-work-index-mdx": () => import("/vercel/workpath0/src/pages/work/index.mdx" /* webpackChunkName: "component---src-pages-work-index-mdx" */),
  "component---src-pages-work-masonite-advisar-index-mdx": () => import("/vercel/workpath0/src/pages/work/masonite/advisar/index.mdx" /* webpackChunkName: "component---src-pages-work-masonite-advisar-index-mdx" */),
  "component---src-pages-work-masonite-door-configurator-index-mdx": () => import("/vercel/workpath0/src/pages/work/masonite/door-configurator/index.mdx" /* webpackChunkName: "component---src-pages-work-masonite-door-configurator-index-mdx" */),
  "component---src-pages-work-masonite-index-mdx": () => import("/vercel/workpath0/src/pages/work/masonite/index.mdx" /* webpackChunkName: "component---src-pages-work-masonite-index-mdx" */),
  "component---src-pages-work-masonite-masonite-connected-index-mdx": () => import("/vercel/workpath0/src/pages/work/masonite/masonite-connected/index.mdx" /* webpackChunkName: "component---src-pages-work-masonite-masonite-connected-index-mdx" */),
  "component---src-pages-work-playa-index-mdx": () => import("/vercel/workpath0/src/pages/work/playa/index.mdx" /* webpackChunkName: "component---src-pages-work-playa-index-mdx" */),
  "component---src-pages-work-race-times-index-mdx": () => import("/vercel/workpath0/src/pages/work/race-times/index.mdx" /* webpackChunkName: "component---src-pages-work-race-times-index-mdx" */),
  "component---src-pages-work-slack-themes-index-mdx": () => import("/vercel/workpath0/src/pages/work/slack-themes/index.mdx" /* webpackChunkName: "component---src-pages-work-slack-themes-index-mdx" */),
  "component---src-pages-work-soleventure-index-mdx": () => import("/vercel/workpath0/src/pages/work/soleventure/index.mdx" /* webpackChunkName: "component---src-pages-work-soleventure-index-mdx" */),
  "component---src-pages-work-zakucons-index-mdx": () => import("/vercel/workpath0/src/pages/work/zakucons/index.mdx" /* webpackChunkName: "component---src-pages-work-zakucons-index-mdx" */)
}

