import React, { Component } from 'react'

import { StaticQuery, graphql } from "gatsby"

import Img from 'gatsby-image'

class ImageIcon extends Component {

  render() { 
    const {
      data,
      srcName,
      title,
      width,
    } = this.props

    var fluidSrc = data.allImageSharp.edges.find( function( image ){
      const fileName = image.node.fluid.originalName.replace(/\.[^/.]+$/, "")
      return fileName === srcName;
    })

    let titleText
    titleText = title
    
    return (
      <div>
        <Img
          fluid={fluidSrc.node.fluid}
          alt={titleText}
          loading="lazy"
        />
        {titleText && 
          <p>{titleText}</p>
        }
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges{
            node{
              fluid {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
    render={data => 
      <ImageIcon data={data} {...props} />
    }
  />
)