import React from 'react'
import { Section, Container, CSSGrid, Col } from '../Grid'
import { ButtonGhost, ButtonLinkGhost } from '../Button'
import { CardContainer, CardLink, CardBody,IconImgContainer } from '../Card'
import { Text } from '../Text'
import { designTokens } from '../Theme/designTokens'
import ImageTest from '../ImageTest'

export const CallToAction = () => {
  return(
    <Section name="End">
      <Container>
        <CSSGrid>
          <Col col={1/4} media={{ phone: 1, tablet: 1 }}></Col>
          <Col col={1/2} media={{ phone: 1, tablet: 1 }}>
            <div style={{ textAlign: 'center', marginBottom: designTokens.space[7]}}>
              <h5>Thanks for reading!</h5>
              <p>If you'd like to see more work, head back to view all work or check out one of my selected projects:</p>
              <ButtonLinkGhost color={({ theme }) => theme.highlightedText} to={'/work'}>Back to Work</ButtonLinkGhost>
            </div>
          </Col>
          <Col col={1/4} media={{ phone: 1, tablet: 1 }}></Col>

          <Col col={1/3} media={{ phone: 1, tablet: 1/2 }}>
            <CardLink to={'/work/masonite'}>
              <CardContainer>
                <CardBody>
                  <IconImgContainer>
                    <ImageTest filename={'masonite-icon'}/>
                  </IconImgContainer>
                  <CSSGrid>
                    <Col col={1/4} media={{ phone: 1/5 }}></Col>
                    <Col col={3/4} media={{ phone: 4/5 }}>
                      <Text bold>Masonite</Text>
                      <small>Simplifying the home-remodeling experience</small>
                    </Col>
                  </CSSGrid>
                </CardBody>
              </CardContainer>
            </CardLink>
          </Col>

          <Col col={1/3} media={{ phone: 1, tablet: 1/2 }}>
            <CardLink to={'/work/chargebacks911'}>
              <CardContainer>
                <CardBody>
                  <IconImgContainer>
                    <ImageTest filename={'cb911-icon'}/>
                  </IconImgContainer>
                  <CSSGrid>
                    <Col col={1/4} media={{ phone: 1/5 }}></Col>
                    <Col col={3/4} media={{ phone: 4/5 }}>
                      <Text bold>Chargebacks911</Text>
                      <small>Helping online merchants optimize profitability</small>
                    </Col>
                  </CSSGrid>
                </CardBody>
              </CardContainer>
            </CardLink>
          </Col>

          <Col col={1/3} media={{ phone: 1, tablet: 1/2 }}>
            <CardLink to={'/work/disputelab'}>
              <CardContainer>
                <CardBody>
                  <IconImgContainer>
                    <ImageTest filename={'disputelab-icon'}/>
                  </IconImgContainer>
                  <CSSGrid>
                    <Col col={1/4} media={{ phone: 1/5 }}></Col>
                    <Col col={3/4} media={{ phone: 4/5 }}>
                      <Text bold>DisputeLab</Text>
                      <small>Payments and Large Data Management for Banks</small>
                    </Col>
                  </CSSGrid>
                </CardBody>
              </CardContainer>
            </CardLink>
          </Col>
        </CSSGrid>
        <CSSGrid>
          <Col col={1/6} media={{ phone: 1, tablet: 1/4 }}></Col>
            <Col col={4/6} media={{ phone: 1, tablet: 1/2 }}>
              <a href="mailto:parag.ryan@gmail.com?subject=Hey Ryan!">
                <CardContainer>
                  <CardBody>
                    <p className='lead'>Have a project, or want to talk about dope ideas?</p>
                    <Text size={designTokens.fontSizes[0]} marginBottom={designTokens.space[3]} color={({ theme }) => theme.grey500}>I'm currently open for opportunities.</Text>
                    <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                      <ButtonGhost color={({ theme }) => theme.highlightedText}>Let's Chat</ButtonGhost>
                    </div>
                  </CardBody>
                </CardContainer>
              </a>
            </Col>
            <Col col={1/6} media={{ phone: 1, tablet: 1/4 }}></Col>
        </CSSGrid>
      </Container>
    </Section>
  )
}