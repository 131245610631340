import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, keywords, title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        return (
          <>
            <Helmet
              htmlAttributes={{
                lang,
              }}
              title={title}
              titleTemplate={`${data.site.siteMetadata.title} | %s`}
              link={[
                {
                  rel: 'stylesheet',
                  href: 'https://fonts.googleapis.com/css?family=Inter:400,600,700,900&display=swap'
                }
              ]}
              meta={[
                {
                  name: 'description',
                  content: metaDescription,
                },
                {
                  property: 'og:title',
                  content: 'Ryan Parag | ' + title,
                },
                {
                  property: 'og:description',
                  content: metaDescription,
                },
                {
                  property: 'og:type',
                  content: 'website',
                },
                {
                  property: 'og:image',
                  content: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/813538/sm-image.png',
                },
                {
                  name: 'twitter:card',
                  content: 'summary_large_image',
                },
                {
                  name: 'twitter:creator',
                  content: data.site.siteMetadata.author,
                },
                {
                  name: 'twitter:title',
                  content: 'Ryan Parag | ' + title,
                },
                {
                  name: 'twitter:description',
                  content: metaDescription,
                },
                {
                  name: 'twitter:image',
                  content: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/813538/sm-image.png',
                },
                {
                  name:'viewport',
                  content: 'width=device-width, initial-scale=1, maximum-scale=1'
                },
                {
                  name:'image',
                  content: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/813538/sm-image.png'
                },
                {
                  itemprop: 'name',
                  content: 'Ryan Parag | ' + title
                },
                {
                  itemprop: 'description',
                  content: metaDescription
                },
                {
                  itemprop: 'image',
                  content: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/813538/sm-image.png'
                }
              ]
                .concat(
                  keywords.length > 0
                    ? {
                        name: 'keywords',
                        content: keywords.join(', '),
                      }
                    : []
                )
                .concat(meta)}
            />
            </>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
