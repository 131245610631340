import { Link } from 'gatsby';
import Layout from "../../../../src/components/layout";
import SEO from "../../../../src/components/seo";
import { IntroProject } from "../../../../src/components/Intro/Intro";
import { StyledLink } from "../../../../src/components/StyledLink";
import { Section, Container, CSSGrid, Col } from "../../../../src/components/Grid";
import { CardContainer, CardBody } from "../../../../src/components/Card";
import { Box, ProjectBox } from "../../../../src/components/Box";
import { Text } from "../../../../src/components/Text";
import { IconBox, IconCard } from "../../../../src/components/IconBox";
import { designTokens } from "../../../../src/components/Theme/designTokens";
import { CallToAction } from "../../../../src/components/CallToAction";
import { ButtonOutbound } from "../../../../src/components/Button";
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdHammer from 'react-ionicons/lib/MdHammer';
import React from 'react';
export default {
  Link,
  Layout,
  SEO,
  IntroProject,
  StyledLink,
  Section,
  Container,
  CSSGrid,
  Col,
  CardContainer,
  CardBody,
  Box,
  ProjectBox,
  Text,
  IconBox,
  IconCard,
  designTokens,
  CallToAction,
  ButtonOutbound,
  MdPerson,
  MdHammer,
  React
};