import SEO from "../../../../src/components/seo";
import { Intro } from "../../../../src/components/Intro/Intro";
import { StyledLink } from "../../../../src/components/StyledLink";
import Projects from "../../../../src/components/Projects";
import { Section, Container, CSSGrid, Col } from "../../../../src/components/Grid";
import { CardOutbound, CardContainer, CardBody } from "../../../../src/components/Card";
import { designTokens } from "../../../../src/components/Theme/designTokens";
import { Box } from "../../../../src/components/Box";
import { Text } from "../../../../src/components/Text";
import ImageIcon from "../../../../src/components/ImageIcon";
import { Button, ButtonOutbound, ButtonGhost } from "../../../../src/components/Button";
import portfolioPDF from "../../../../src/static/Ryan Parag-Product Designer_Portfolio.pdf";
import { motion } from 'framer-motion';
import { Badge } from "../../../../src/components/Badge";
import { List, ListItem, ListInner } from "../../../../src/components/List";
import { Pill } from "../../../../src/components/Pill";
import { RandomizerAbout } from "../../../../src/components/RandomizerAbout";
import React from 'react';
export default {
  SEO,
  Intro,
  StyledLink,
  Projects,
  Section,
  Container,
  CSSGrid,
  Col,
  CardOutbound,
  CardContainer,
  CardBody,
  designTokens,
  Box,
  Text,
  ImageIcon,
  Button,
  ButtonOutbound,
  ButtonGhost,
  portfolioPDF,
  motion,
  Badge,
  List,
  ListItem,
  ListInner,
  Pill,
  RandomizerAbout,
  React
};