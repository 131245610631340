export default [
  {
    title: 'Coding in the Wild',
    description: 'Coding for Digital Product Design',
    link: 'https://codinginthewild.com/coding-for-digital-product-design-dc7256ca44b7'
  },{
    title: 'InVision',
    description: '8 examples of accessible design for your inspiration',
    link: 'https://www.invisionapp.com/inside-design/8-examples-accessible-design-inspiration/'
  },{
    title: 'Integromat',
    description: 'The Best Wireframing Tools',
    link: 'https://www.integromat.com/en/blog/best-wireframing-tools/'
  }
]