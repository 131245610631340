export default [
  {
    title: 'This Is Not a T-Shirt: A Brand, a Culture, a Community--a Life in Streetwear',
    author:'Bobby Hundreds',
    link: 'https://www.goodreads.com/book/show/41940450-this-is-not-a-t-shirt'
  },{
    title: 'A Pattern Language: Towns, Buildings, Construction (Center for Environmental Structure Series)',
    author:'Christopher Alexander, Sara Ishikawa, Murray Silverstein, Max Jacobson, Ingrid Fiksdahl-King, Shlomo Angel',
    link: 'https://www.goodreads.com/book/show/79766.A_Pattern_Language?from_search=true&from_srp=true&qid=Bukw9HEdGg&rank=1'
  },{
    title: 'Complexity: The Emerging Science at the Edge of Order and Chaos',
    author:'M. Mitchell Waldrop',
    link: 'https://www.goodreads.com/book/show/337123.Complexity?from_search=true&from_srp=true&qid=NnhEiVN3y4&rank=1'
  },{
    title: 'Dataclysm: Love, Sex, Race, and Identity--What Our Online Lives Tell Us about Our Offline Selves',
    author:'Christian Rudder',
    link: 'https://www.goodreads.com/book/show/25809994-dataclysm?from_search=true&from_srp=true&qid=aGuNDi5FgU&rank=1'
  },{
    title: 'The Social Animal: The Hidden Sources of Love, Character, and Achievement',
    author:'David Brooks',
    link: 'https://www.goodreads.com/book/show/9628743-the-social-animal?from_search=true&from_srp=true&qid=4Za2yEj3mg&rank=1'
  },{
    title: 'The Innovator\'s Dilemma: When New Technologies Cause Great Firms to Fail',
    author:'Clayton Christensen',
    link: 'https://www.goodreads.com/book/show/18750852-the-innovator-s-dilemma?from_search=true&from_srp=true&qid=L3z6DwoEBc&rank=1'
  }
]